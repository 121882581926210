import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';

import {
  blogStyles,
  ClickableLink,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output1 from 'assets/png/blogs/T9-output1.png';

import { step1, step2, step3, step4, step5, step6, step7 } from './codebase';

const BlogsAndroidComplexLayout = () => {
  const styles = blogStyles();
  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />

      <Typography paragraph>
        In this tutorial, I am going to teach you how to load an image from the
        internet or maybe from you server using Glide image library. I&apos;ll
        also teach you how to create a good UI design like Instagram using Card
        View and Recycler View.
      </Typography>
      <Typography paragraph>
        <b>What is Glide image library?</b>
      </Typography>
      <Typography paragraph>
        Glide is a fast and efficient open source media management and image
        loading framework for Android OS. It can wrap media decoding, memory and
        disk caching, and resource pooling into a simple and easy to use
        interface. Glide&apos;s primary focus is on making scrolling any kind of
        a list of images as smooth and fast as possible, but Glide is also
        effective for almost any case where you need to fetch, resize, and
        display a remote image. To use Glide Image Library, You need to add this
        dependency in your build.gradle.
      </Typography>

      <CodeSnippet codes={step1} />

      <Typography paragraph>
        And add this permission in your <b>AndroidManifest.xml</b>. You&apos;re
        images won&apos;t show if you forget to include this uses-permission
        tag.
      </Typography>
      <CodeSnippet codes={step2} />

      <Typography paragraph>
        <b>What are card views and recycler views?</b>
      </Typography>
      <Typography paragraph>
        Card views and recycler views are more efficient alternative widget for
        list views and custom views. You can use them to create complex lists
        and cards with material design styles in your apps such as
        elevation(drop shadow), corner radius and many more. It also simplifies
        the display and handling of large data sets by providing layout managers
        and common animations. In this tutorial, we&apos;ll try to create an
        Instagram feed like app using Glide, card view and recycler view. The
        final output will look like this.
      </Typography>
      <Typography paragraph>
        You can download all the icons that we&apos;ll use in this link:{' '}
        <ClickableLink href="" />
      </Typography>
      <Typography paragraph>
        Open your Android Studio and create a new blank project. Create new
        classes just like what you see in the image below. Open Feed.java then
        copy and paste the codes provided below. This will be the model for our
        recycler view items. Every feed in our list contains the user&apos;s
        name, location, display image, description of the photo and time stamp.
      </Typography>
      <CodeSnippet codes={step3} />

      <Typography paragraph>
        Now let&apos;s create the layout for our card view. Create a new xml
        layout file and name it <b>cardview_feed.xml</b>.
      </Typography>
      <CodeSnippet codes={step4} />

      <Typography paragraph>
        Now you should have a layout that looks like in the image below. Then
        open <b>FeedAdapter.java</b> and the following codes. I&apos;ve put some
        comments so you can understand the codes below.
      </Typography>
      <CodeSnippet codes={step5} />

      <Typography paragraph>
        Now open your <b>activity_main.xml</b> and add a recycler view in your
        xml layout.
      </Typography>
      <CodeSnippet codes={step6} />

      <Typography paragraph>
        Then open <b>MainActivity.java</b> then add the following codes.
      </Typography>
      <CodeSnippet codes={step7} />

      <Typography paragraph>
        Now you can run and test you app if it is working. The final output of
        this tutorial should be like this.
      </Typography>
      <ScreenShot source={output1} />

      <Divider className={styles.divider} />
    </BlogContentLayout>
  );
};

export default BlogsAndroidComplexLayout;
