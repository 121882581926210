export const step1 = `<RelativeLayout    
    xmlns:android="http://schemas.android.com/apk/res/android"    
    android:layout_width="match_parent"    
    android:layout_height="wrap_content">  

        <ImageView        
            android:id="@+id/company_logo"        
            android:layout_width="50dp"        
            android:layout_height="50dp"        
            android:layout_alignParentLeft="true"        
            android:layout_alignParentStart="true"        
            android:layout_alignParentTop="true"        
            android:layout_margin="10dp"        
            android:src="@drawable/logo_android"/>    

        <TextView        
            android:id="@+id/company_name"        
            android:layout_width="wrap_content"        
            android:layout_height="wrap_content"        
            android:layout_alignTop="@+id/company_logo"        
            android:layout_toEndOf="@+id/company_logo"        
            android:layout_toRightOf="@+id/company_logo"        
            android:text="Company Name"        
            android:textColor="@color/colorPrimaryDark"        
            android:textSize="14sp"        
            android:textStyle="bold"/>    

        <TextView        
            android:id="@+id/company_description"        
            android:layout_width="wrap_content"        
            android:layout_height="wrap_content"        
            android:layout_below="@+id/company_name"        
            android:layout_marginBottom="10dp"        
            android:layout_marginRight="10dp"        
            android:layout_marginTop="4dp"        
            android:layout_toEndOf="@+id/company_logo"        
            android:layout_toRightOf="@+id/company_logo"        
            android:text="companyName description"        
            android:textSize="12sp"/>

</RelativeLayout>`;

export const step2 = `<?xml version="1.0" encoding="utf-8"?>
<RelativeLayout    
    xmlns:android="http://schemas.android.com/apk/res/android"    
    xmlns:tools="http://schemas.android.com/tools"    
    android:id="@+id/activity_main"    
    android:layout_width="match_parent"    
    android:layout_height="match_parent"    
    tools:context="info.androidprime.customizedlistview.MainActivity">   

        <ListView        
            android:id="@+id/listView"        
            android:layout_width="match_parent"        
            android:layout_height="match_parent"/>

</RelativeLayout>`;

export const step3 = `public class Constants {    
    public static final int[] companyLogo = {            
        R.drawable.logo_android,            
        R.drawable.logo_apple,           
        R.drawable.logo_facebook,            
        R.drawable.logo_google,            
        R.drawable.logo_samsung,            
        R.drawable.logo_twitter,            
        R.drawable.logo_instagram    
    };    
    public static final String[] companyName = {            
        "Android",            
        "Apple",            
        "Facebook",            
        "Google",            
        "Samsung",            
        "Twitter",            
        "Instagram"    
    };    
    public static final String[] description = {            
        "Android is a mobile operating system developed by " +                    
        "Google, based on the Linux kernel and " +                    
        "designed primarily for touchscreen mobile " +                    
        "devices such as smartphones and tablets. ",

        "Apple Inc. is an American " +                    
        "multinational technology company headquartered " +                    
        "in Cupertino, California, that designs, develops, " +                    
        "and sells consumer electronics, computer software, " +                    
        "and online services.",  

        "Facebook is a for-profit corporation and online social " +                    
        "media and social networking service based in " +                    
        "Menlo Park, California, United States. " +                    
        "The Facebook website was launched on February 4, " +                    
        "2004, by Mark Zuckerberg, along with fellow Harvard " +                    
        "College students and roommates, Eduardo Saverin, " +                    
        "Andrew McCollum, Dustin Moskovitz, and Chris Hughes.",

        "Google is an American " +                    
        "multinational technology company specializing in " +                    
        "Internet-related services and products that include " +                    
        "online advertising technologies, search, cloud computing, " +                    
        "and software.", 

        "Samsung is a South Korean multinational conglomerate company " +                    
        "headquartered in Samsung Town, Seoul. It comprises numerous " +                    
        "subsidiaries and affiliated businesses, most of them united " +                    
        "under the Samsung brand, and is the largest South Korean " +                    
        "chaebol (business conglomerate).",  

        "Twitter is an online social networking service that enables users to " +                    
        "send and read short 140-character messages called "tweets". " +                    
        "Registered users can read and post tweets, but those who are " +                    
        "unregistered can only read them. ",    

        "Instagram is an online " +                    
        "mobile photo-sharing, video-sharing, and social networking " +                    
        "service that enables its users to take pictures and videos, " +                    
        "and share them either publicly or privately on the app, as " +                    
        "well as through a variety of other social networking platforms, " +                    
        "such as Facebook, Twitter, Tumblr, and Flickr."    
    };
}
`;

export const step4 = `public class CustomListItem {    
    private int icon;    
    private String companyName, description;  

    public CustomListItem(int icon, String companyName, String description) {        
        this.icon = icon;        
        this.companyName = companyName;        
        this.description = description;    
    }   

    public int getIcon() {        
        return icon;    
    }   

    public void setIcon(int icon) {        
        this.icon = icon;    
    }  

    public String getCompanyName() {        
        return companyName;    
    }  

    public void setCompanyName(String companyName) {        
        this.companyName = companyName;    
    }   

    public String getDescription() {        
        return description;    
    }    

    public void setDescription(String description) {        
        this.description = description;    
    }
}
`;

export const step5 = `public class CustomAdapter extends BaseAdapter{    
    private Activity activity;    
    private ArrayList<CustomListItem> items;    
    private LayoutInflater inflater;    

    public CustomAdapter(Activity activity, ArrayList<CustomListItem> items) {        
        this.activity = activity;        
        this.items = items;    
    }    

    @Override    
    public int getCount() {        
        return items.size();    
    }   

    @Override    
    public Object getItem(int location) {        
        return items.get(location);    
    }    

    @Override    
    public long getItemId(int position) {        
        return position;    
    }    

    @Override    
    public View getView(int position, View convertView, ViewGroup viewGroup) {        
        if (inflater == null)            
            inflater = (LayoutInflater) activity.getSystemService(Context.LAYOUT_INFLATER_SERVICE);  

        if (convertView == null)            
            convertView = inflater.inflate(R.layout.custom_item, null);        

        ImageView imageView = (ImageView) convertView.findViewById(R.id.company_logo);        
        TextView name = (TextView) convertView.findViewById(R.id.company_name);        
        TextView description = (TextView) convertView.findViewById(R.id.company_description);   
        CustomListItem item = items.get(position);        

        imageView.setImageResource(item.getIcon());        
        name.setText(item.getCompanyName());        
        description.setText(item.getDescription());  

        return convertView;    
    }
}
`;

export const step6 = `public class MainActivity extends AppCompatActivity {    
    private ListView listView;    
    private CustomAdapter adapter;    
    private ArrayList<CustomListItem> items;    
    
    @Override    
    protected void onCreate(Bundle savedInstanceState) {        
        super.onCreate(savedInstanceState);        
        setContentView(R.layout.activity_main);        
        listView = (ListView) findViewById(R.id.listView);  
              
        items = new ArrayList<>();        
        items.add(new CustomListItem(
            Constants.companyLogo[0],                
            Constants.companyName[0], 
            Constants.description[0]
        ));        
        items.add(new CustomListItem(
            Constants.companyLogo[1],                
            Constants.companyName[1], 
            Constants.description[1]
        ));        
        items.add(new CustomListItem(
            Constants.companyLogo[2],                
            Constants.companyName[2], 
            Constants.description[2]
        ));        
        items.add(new CustomListItem(
            Constants.companyLogo[3],                
            Constants.companyName[3], 
            Constants.description[3]
        ));        
        items.add(new CustomListItem(
            Constants.companyLogo[4],                
            Constants.companyName[4], 
            Constants.description[4]
        ));        
        items.add(new CustomListItem(
            Constants.companyLogo[5],                
            Constants.companyName[5], 
            Constants.description[5]
        ));        
        items.add(new CustomListItem(
            Constants.companyLogo[6],                
            Constants.companyName[6], 
            Constants.description[6]
        ));        

        adapter = new CustomAdapter(this, items);        
        listView.setAdapter(adapter);    
    }
}
`;
