import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import AND400 from 'assets/jpg/AND-400.jpg';
import AND401 from 'assets/jpg/AND-401.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: '3vh',
    background: '#313D3F',
  },
  certificateContainer: {
    margin: '2em',
  },
  certificate: {
    height: '20em',
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      height: '12em',
    },
  },
}));

const Certifications = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Grid container justify="center" className={styles.root}>
        <Grid item>
          <Typography
            variant="h4"
            style={{ color: 'white', marginTop: ' 4em' }}
          >
            Certifications
          </Typography>
        </Grid>
        <Grid
          container
          justify="center"
          spacing={4}
          className={styles.certificateContainer}
        >
          <Grid item>
            <img
              src={AND400}
              alt="AND-400 Certificate"
              className={styles.certificate}
            />
          </Grid>
          <Grid item>
            <img
              src={AND401}
              alt="AND-401 Certificate"
              className={styles.certificate}
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Certifications;
