import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

import penguin from 'assets/png/fishing-game/penguin.png';
import googleLink from 'assets/png/google-link.png';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '4em',
    marginBottom: '4em',
  },
  title: {
    fontWeight: 700,
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      marginBottom: '0.5em',
    },
  },
  caption: {
    fontWeight: 300,
    maxWidth: '35em',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.2rem',
    },
  },
  penguinContainer: {
    marginRight: '4em',
  },
  penguin: {
    height: '20em',
    width: 'auto',
    marginRight: '5em',
    [theme.breakpoints.down('lg')]: {
      height: '18em',
    },
    [theme.breakpoints.only('sm')]: {
      height: '15em',
    },
    [theme.breakpoints.only('xs')]: {
      width: '80vw',
      height: 'auto',
      marginRight: 0,
    },
  },
  googleLink: {
    height: '6em',
    width: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '5em',
      marginBottom: '4em',
    },
    [theme.breakpoints.down('sm')]: {
      height: '4em',
      marginBottom: '3em',
    },
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const GameDescription = () => {
  const styles = useStyles();
  const onGoogleLinkClicked = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.mcua.fishing.game',
      '_blank'
    );
  };
  return (
    <React.Fragment>
      <Grid
        container
        direction="row"
        className={styles.root}
        alignItems="center"
        justify="center"
      >
        <Grid item lg={6}>
          <Grid
            item
            container
            direction="column"
            className={styles.penguinContainer}
            justify="center"
            alignItems="center"
          >
            <Grid item>
              <img
                src={penguin}
                alt="Penguin Character"
                className={styles.penguin}
              />
            </Grid>
            <Grid item>
              <Button
                disableRipple
                disableFocusRipple
                className={styles.button}
                onClick={onGoogleLinkClicked}
              >
                <img
                  src={googleLink}
                  alt="Google Link"
                  className={styles.googleLink}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={6}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography variant="h4" className={styles.title}>
                Fish Games For Kids | Trawling Penguin Games
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h6" paragraph className={styles.caption}>
                Dive into the deep sea fishing games to catch a fish with your
                friend, cute penguin. Go in an amazing adventure playing this
                fish games for kids. Trawling has never been so fun before, so
                try out this 2 different penguin games. In both game modes the
                goal is very simple - to catch a fish, every little one you can
                see!
              </Typography>
              <Typography variant="h6" paragraph className={styles.caption}>
                However, the fun doesn’t stop there! There’s more to explore in
                fish catalogue. You can find it on home screen. Meet all the
                swimming creatures in this fish games for kids, including cute
                tuna fish, but also a dangerous pufferfish and sweet angelfish.
                In deep sea fishing games all these fishes swimming in the sea
                need to be caught. Even when you think that you’ve catched them
                all, there’s more to come.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GameDescription;
