export const step1 = `<uses-permission android:name="android.permission.SET_WALLPAPER"/>`;

export const step2 = `public class ImageAdapter extends BaseAdapter {    
        private Context mContext;    
        public Integer[] mThumbIds = {            
                R.drawable.wallpaper_1,            
                R.drawable.wallpaper_2,            
                R.drawable.wallpaper_3,            
                R.drawable.wallpaper_4,            
                R.drawable.wallpaper_5,            
                R.drawable.wallpaper_6,            
                R.drawable.wallpaper_7,            
                R.drawable.wallpaper_8,            
                R.drawable.wallpaper_9,            
                R.drawable.wallpaper_10
        };    
        public ImageAdapter(Context c) {        
                mContext = c;    
        }    
        
        @Override    
        public int getCount() {        
                return mThumbIds.length;    
        }    
        
        @Override    
        public Object getItem(int position) {        
                return mThumbIds[position];    
        }    
        
        @Override    
        public long getItemId(int position) {        
                return 0;    
        }    
        
        @Override    
        public View getView(int position, View convertView, ViewGroup parent) {        
                ImageView imageView = new ImageView(mContext);        
                imageView.setImageResource(mThumbIds[position]);        
                imageView.setScaleType(imageView.getScaleType().CENTER_CROP);        
                imageView.setLayoutParams(new GridView.LayoutParams(LayoutParams.WRAP_CONTENT, 400));        
                return imageView;    
        }
}`;

export const step3 = `<?xml version="1.0" encoding="utf-8"?>
<RelativeLayout    
        xmlns:android="http://schemas.android.com/apk/res/android"    
        xmlns:tools="http://schemas.android.com/tools"    
        android:id="@+id/activity_image_preview"    
        android:layout_width="match_parent"    
        android:layout_height="match_parent"    
        tools:context="info.androidprime.wallpaperapplication.ImagePreview">    

        <ImageView        
                android:id="@+id/full_image_view"        
                android:layout_width="fill_parent"        
                android:layout_height="fill_parent"/>

</RelativeLayout>`;

export const step4 = `public class ImagePreview extends AppCompatActivity {    
        @Override    
        protected void onCreate(Bundle savedInstanceState) {        
                super.onCreate(savedInstanceState);        
                setContentView(R.layout.activity_image_preview);        
                //get the id of the selected wallpaper        
                Intent intent = getIntent();        
                int imgResource = intent.getExtras().getInt("id");        
                ImageView imageView = (ImageView) findViewById(R.id.full_image_view);        
                imageView.setImageResource(imgResource);    
        }
}`;

export const step5 = `<?xml version="1.0" encoding="utf-8"?>
<RelativeLayout    
        xmlns:android="http://schemas.android.com/apk/res/android"    
        xmlns:tools="http://schemas.android.com/tools"    
        android:id="@+id/activity_main"    
        android:layout_width="match_parent"    
        android:layout_height="match_parent"    
        android:padding="16dp"    
        tools:context="info.androidprime.wallpaperapplication.MainActivity">    

        <GridView        
                android:id="@+id/wallpaperGridView"        
                android:layout_width="match_parent"        
                android:layout_height="match_parent"        
                android:columnWidth="90dp"        
                android:gravity="center"        
                android:horizontalSpacing="10dp"        
                android:numColumns="2"        
                android:stretchMode="columnWidth"        
                android:verticalSpacing="10dp"/>

</RelativeLayout>`;

export const step6 = `public class MainActivity extends AppCompatActivity {    
        @Override    
        protected void onCreate(Bundle savedInstanceState) {        
                super.onCreate(savedInstanceState);        
                setContentView(R.layout.activity_main);        
                GridView gridView = (GridView) findViewById(R.id.wallpaperGridView);        
                gridView.setAdapter(new ImageAdapter(this));        
                registerForContextMenu(gridView);    
        }    
        
        @Override    
        public void onCreateContextMenu(ContextMenu menu, View v, ContextMenuInfo menuInfo) {        
                super.onCreateContextMenu(menu, v, menuInfo);        
                AdapterView.AdapterContextMenuInfo cmi = (AdapterContextMenuInfo) menuInfo;        
                menu.add(1, cmi.position, 0, "Set as wallpaper");        
                menu.add(2, cmi.position, 0, "View full image");    
        }    
        
        @Override    
        public boolean onContextItemSelected(MenuItem item) {        
                GridView g = (GridView) findViewById(R.id.wallpaperGridView);        
                int resourceID = (int) g.getItemAtPosition(item.getItemId());        
                switch (item.getGroupId()) {            
                        case 1:                
                        final WallpaperManager wallpaperManager = WallpaperManager.getInstance(getApplicationContext());                
                        try {                    
                                wallpaperManager.setResource(resourceID);                   
                                 Toast.makeText(getApplicationContext(), "Wallpaper Changed", Toast.LENGTH_SHORT).show();                
                                } catch (Exception e) {                    
                                        //do something here                
                                }                
                                break;            
                        case 2:                
                        Intent intent = new Intent(MainActivity.this, ImagePreview.class);                
                        intent.putExtra("id", resourceID);                
                        startActivity(intent);                
                        break;            
                        
                        default:                
                        break;        
                }        
                return true;    
        }
}`;
