import { createMuiTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

const primaryColor = '#2196F3';
const primaryDarkColor = '#1976D2';
const primaryLightColor = '#BBDEFB';

const secondaryColor = '#f50057';
const secondaryDarkColor = '#ab003c';
const secondaryLightColor = '#f73378';

const accentColor = '#FFC107';
const primaryTextColor = '#212121';
const secondaryTextColor = '#757575';
const dividerColor = '#BDBDBD';

const breakpoints = createBreakpoints({});

const theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
      dark: primaryDarkColor,
      light: primaryLightColor,
    },
    secondary: {
      main: secondaryColor,
      dark: secondaryDarkColor,
      light: secondaryLightColor,
    },
    accent: {
      main: accentColor,
    },
    text: {
      primary: primaryTextColor,
      secondary: secondaryTextColor,
    },
    divider: dividerColor,
  },
  typography: {
    h4: {
      [breakpoints.only('md')]: {
        fontSize: '2rem',
      },
      [breakpoints.only('sm')]: {
        fontSize: '1.8rem',
      },
      [breakpoints.only('xs')]: {
        fontSize: '1.6rem',
      },
    },
    subtitle1: {
      [breakpoints.only('md')]: {
        fontSize: '1rem',
      },
      [breakpoints.only('sm')]: {
        fontSize: '0.95rem',
      },
      [breakpoints.only('xs')]: {
        fontSize: '0.85rem',
      },
    },
    learnButton: {
      borderColor: primaryTextColor,
      color: primaryTextColor,
      borderWidth: 2,
      textTransform: 'none',
      borderRadius: 50,
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
  },
  overrides: {
    MuiTab: {
      root: {
        minWidth: '100px',
      },
      wrapper: {
        width: 'min-content',
      },
    },
  },
});

export default theme;
