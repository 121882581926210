import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  root: {
    background: '#FAFAFA',
    padding: 0,
    margin: '2em',
    marginLeft: '0.5em',
    marginRight: '0.5em',
    borderRadius: '0.5em',
  },
  cardContent: {
    padding: 0,
  },
  banner: {
    height: '14em',
    width: 'auto',
  },
  content: {
    margin: '1em',
  },
  title: {},
  chip: {
    marginRight: '0.5em',
    marginTop: '0.5em',
  },
  chipContainer: {
    marginTop: '0.5em',
  },
  author: {},
  bullet: {
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  duration: {
    fontWeight: 300,
  },
  divider: {
    margin: '1em',
  },
  date: {},
  buttonContainer: {
    paddingTop: '1em',
    paddingRight: '1em',
  },
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const BlogContentItem = ({
  thumbnail,
  title,
  tags,
  author,
  duration,
  date,
  path,
}) => {
  const styles = useStyles();
  const history = useHistory();

  const onClickHandler = () => {
    history.push(path);
  };
  return (
    <Card className={styles.root} variant="outlined">
      <CardContent className={styles.cardContent}>
        <a href={path}>
          <img src={thumbnail} alt="Blog Thumbnail" className={styles.banner} />
        </a>
        <Grid container direction="column" className={styles.content}>
          <Grid item>
            <Typography variant="h6" className={styles.title}>
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Grid container direction="column">
              <Grid item container direction="row" alignItems="center">
                <Grid item>
                  <Typography variant="subtitle1" className={styles.author}>
                    Author: {author}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" className={styles.bullet}>
                    •
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1" className={styles.duration}>
                    {duration} mins read
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="caption" className={styles.date}>
                  published on {date}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={styles.chipContainer}>
            <Grid item>
              {tags.map((tag) => {
                return (
                  <Chip
                    key={tag}
                    label={tag}
                    color="primary"
                    variant="outlined"
                    className={styles.chip}
                  />
                );
              })}
            </Grid>
          </Grid>
        </Grid>
        <Grid container justify="flex-end" className={styles.buttonContainer}>
          <Grid item>
            <Button
              className={styles.button}
              disableFocusRipple
              disableRipple
              onClick={onClickHandler}
            >
              Read More
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

BlogContentItem.propTypes = {
  thumbnail: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  author: PropTypes.string.isRequired,
  duration: PropTypes.number.isRequired,
  date: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default BlogContentItem;
