export const step1 = `dependencies {    
        compile 'com.google.android.gms:play-services-plus:9.4.0'    
        compile 'com.google.android.gms:play-services-auth:9.4.0'
}`;

export const step2 = `<uses-permission android:name="android.permission.INTERNET"/>`;

export const step3 = `public class User {    
        private String name, email, userId; 

        public User() {    

        }    
        
        public String getName() {        
                return name;
        }    

        public void setName(String name) {
                this.name = name;
        }
        
        public String getEmail() {
                return email;  
        }
        
        public void setEmail(String email) {
                this.email = email;
        }    
        
        public String getUserId() {
                return userId;
        }
        
        public void setUserId(String userId) {
                this.userId = userId;
        }
}
`;

export const step4 = `public class Config {
        // put all  shared preference keys here
        public static final String KEY_USER_ID = "user_id";
        public static final String KEY_USER_NAME = "user_name";
        public static final String KEY_USER_EMAIL = "user_email";
}`;

export const step5 = `public class MyPreferenceManager {
        private SharedPreferences pref;
        private SharedPreferences.Editor editor;
        private Context mContext;
        private int PRIVATE_MODE = 0;
        private static final String PREF_NAME = "google_signin";
        
        public MyPreferenceManager(Context context) {
                this.mContext = context;
                pref = mContext.getSharedPreferences(PREF_NAME, PRIVATE_MODE);
                editor = pref.edit();
        }
        
        public void storeUser(User user) {
                editor.putString(KEY_USER_ID, user.getUserId());
                editor.putString(KEY_USER_NAME, user.getName());
                editor.putString(KEY_USER_EMAIL, user.getEmail());
                editor.commit();
        }    
        
        public User getUser() {
                if (pref.getString(KEY_USER_ID, null) != null) {
                        String userId, name, email, photoUrl;
                        userId = pref.getString(KEY_USER_ID, null);
                        name = pref.getString(KEY_USER_NAME, null);
                        email = pref.getString(KEY_USER_EMAIL, null);
                        User user = new User();
                        user.setUserId(userId);
                        user.setName(name);
                        user.setEmail(email);
                        return user;
                }
                return null;
        }
        
        public void clear() {
                editor.clear();
                editor.commit();
        }
}`;

export const step6 = `public class AppController extends Application{
        private static AppController mInstance;
        private MyPreferenceManager pref;
        
        @Override
        public void onCreate() {
                super.onCreate();
                mInstance = this;
        }
        
        public static synchronized AppController getInstance() {
                return mInstance;
        }    
        
        public MyPreferenceManager getPrefManager() {
                if (pref == null) {
                        pref = new MyPreferenceManager(this);
                }
                return pref;    
        }
}`;

export const step7 = `<application android:name=".app.AppController"> 
                ...   
</application>`;

export const step8 = `<?xml version="1.0" encoding="utf-8"?>
<RelativeLayout
        xmlns:android="http://schemas.android.com/apk/res/android"
        xmlns:tools="http://schemas.android.com/tools"
        android:id="@+id/activity_login"
        android:layout_width="match_parent"
        android:layout_height="match_parent"
        android:padding="16dp"
        android:background="#DDDDDD"
        tools:context="info.androidprime.googleplussignin.activity.Login">

                <com.google.android.gms.common.SignInButton
                        android:id="@+id/btn_sign_in"
                        android:layout_width="fill_parent"
                        android:layout_height="wrap_content"
                        android:layout_alignParentBottom="true"
                        android:layout_alignParentLeft="true"
                        android:layout_alignParentStart="true"
                        android:layout_marginBottom="50dp"/>

</RelativeLayout>`;

export const step9 = `public class Login extends AppCompatActivity implements GoogleApiClient.OnConnectionFailedListener, OnClickListener {
        //return code for our google sign in
        private static final int RC_SIGN_IN = 1;
        private GoogleApiClient mGoogleApiClient;
        private ProgressDialog mProgressDialog;
        private SignInButton btnSignIn;
        
        @Override
        protected void onCreate(Bundle savedInstanceState) {
                super.onCreate(savedInstanceState);
                setContentView(R.layout.activity_login);
                btnSignIn = (SignInButton) findViewById(R.id.btn_sign_in);
                btnSignIn.setOnClickListener(this);
                // initialize our google api client. We need this for our
                // google sign in
                GoogleSignInOptions googleSignInOptions = new
                GoogleSignInOptions.Builder(GoogleSignInOptions.DEFAULT_SIGN_IN)
                .requestEmail()
                .build();
                
                mGoogleApiClient = new GoogleApiClient.Builder(this)
                .enableAutoManage(this, this)
                .addApi(Auth.GOOGLE_SIGN_IN_API, googleSignInOptions)
                .build();

                // add customizations in our sign in button
                btnSignIn.setSize(SignInButton.SIZE_STANDARD);
                btnSignIn.setScopes(googleSignInOptions.getScopeArray());
                // Change the text inside the google sign in button
                setGooglePlusButtonText(btnSignIn, "Sign in with Google+
                ");
        }
        
        @Override
        public void onStart() {
                super.onStart();
                //checks if we have and existing signed in google account
                OptionalPendingResult<GoogleSignInResult> opr = Auth.GoogleSignInApi.silentSignIn(mGoogleApiClient);
                if (opr.isDone()) {
                        GoogleSignInResult result = opr.get();
                        handleSignInResult(result);
                } else {
                        showProgressDialog("Please wait...");
                        opr.setResultCallback(new ResultCallback<GoogleSignInResult>() {
                                @Override
                                public void onResult(GoogleSignInResult googleSignInResult) {
                                        hideProgressDialog();
                                        handleSignInResult(googleSignInResult);
                                }
                        });
                }    
        }    
        
        @Override
        public void onConnectionFailed(@NonNull ConnectionResult connectionResult) {
                // do something here if google sign in failed
                // e.g. create alert dialog or toast
        }
        
        @Override
        public void onClick(View view) {
                switch (view.getId()) {
                        case R.id.btn_sign_in:
                                signIn();
                                break;
                }
        }
                
        private void signIn() {
                // start an activity for google sign then returns with a result if success or failed
                Intent signInIntent = Auth.GoogleSignInApi.getSignInIntent(mGoogleApiClient);
                startActivityForResult(signInIntent, RC_SIGN_IN);
        }
        
        @Override
        public void onActivityResult(int requestCode, int resultCode, Intent data) {
                super.onActivityResult(requestCode, resultCode, data);
                // results returned by google sign in
                if (requestCode == RC_SIGN_IN) {
                        GoogleSignInResult result = Auth.GoogleSignInApi.getSignInResultFromIntent(data);
                        handleSignInResult(result);
                }
        }
        
        private void handleSignInResult(GoogleSignInResult result) {
                if (result.isSuccess()) {
                        //we store the information in our pref manager
                        GoogleSignInAccount acct = result.getSignInAccount();
                        String userId = acct.getId();
                        String personName = acct.getDisplayName();
                        String email = acct.getEmail();
                        User user = new User();
                        user.setUserId(userId);
                        user.setName(personName);
                        user.setEmail(email);
                        AppController.getInstance().getPrefManager().storeUser(user);
                        // start the home activity after success
                        Intent intent = new Intent(Login.this, Home.class);
                        startActivity(intent);
                }
        }
        
        protected void setGooglePlusButtonText(SignInButton signInButton, String buttonText) {
                //change the text inside the sign in button
                for (int i = 0; i < signInButton.getChildCount(); i++) {
                        View view = signInButton.getChildAt(i);
                        if (view instanceof TextView) {
                                TextView textView = (TextView) view;
                                textView.setText(buttonText);
                                return;
                        }     
                }
        }    
       
        private void showProgressDialog(String msg) {
                //shows the progress dialog
                if (mProgressDialog != null)
                mProgressDialog = null;
                mProgressDialog = new ProgressDialog(Login.this);
                mProgressDialog.setMessage(msg);
                mProgressDialog.setCancelable(false);
                mProgressDialog.show();
        }
        
        private void hideProgressDialog() {
                //hides the progress dialog
                if (mProgressDialog != null && mProgressDialog.isShowing()) {       
                        mProgressDialog.cancel();
                }
        }
}`;

export const step10 = `<?xml version="1.0" encoding="utf-8"?>
<LinearLayout
        xmlns:android="http://schemas.android.com/apk/res/android"
        xmlns:tools="http://schemas.android.com/tools"
        android:id="@+id/activity_home"
        android:layout_width="match_parent"
        android:layout_height="match_parent"
        android:orientation="vertical"
        android:padding="16dp"
        tools:context="info.androidprime.googleplussignin.activity.Home">

        <LinearLayout
                android:id="@+id/llProfile"
                android:layout_width="fill_parent"
                android:layout_height="wrap_content"
                android:layout_marginBottom="20dp"
                android:orientation="vertical">

                <TextView
                        android:id="@+id/txtName"
                        android:layout_width="wrap_content"
                        android:layout_height="wrap_content"
                        android:hint="User display name"
                        android:padding="5dp"/>

                <TextView
                        android:id="@+id/txtEmail"
                        android:layout_width="wrap_content"
                        android:layout_height="wrap_content"
                        android:hint="User email"
                        android:padding="5dp"
                        android:textSize="10dp"
                        android:textStyle="bold"/>

        </LinearLayout>

        <Button
                android:id="@+id/btn_sign_out"
                android:layout_width="fill_parent"
                android:layout_height="wrap_content"
                android:layout_marginBottom="10dp"
                android:text="Logout"
                android:textAllCaps="false"/>

        <Button
                android:id="@+id/btn_revoke_access"
                android:layout_width="fill_parent"
                android:layout_height="wrap_content"
                android:text="Revoke Access"
                android:textAllCaps="false"/>

</LinearLayout>`;

export const step11 = `public class Home extends AppCompatActivity implements GoogleApiClient.OnConnectionFailedListener, OnClickListener {
        private GoogleApiClient mGoogleApiClient;
        private ProgressDialog mProgressDialog;
        private Button btnSignOut, btnRevokeAccess;
        private TextView txtName, txtEmail;

        @Override
        protected void onCreate(Bundle savedInstanceState) {
                super.onCreate(savedInstanceState);
                setContentView(R.layout.activity_home);

                GoogleSignInOptions gso = new GoogleSignInOptions.Builder(GoogleSignInOptions.DEFAULT_SIGN_IN)
                .requestEmail()
                .build();

                mGoogleApiClient = new GoogleApiClient.Builder(this)
                .enableAutoManage(this, this)
                .addApi(Auth.GOOGLE_SIGN_IN_API, gso)
                .build();

                txtName = (TextView) findViewById(R.id.txtName);
                txtEmail = (TextView) findViewById(R.id.txtEmail);
                btnSignOut = (Button) findViewById(R.id.btn_sign_out);
                btnRevokeAccess = (Button) findViewById(R.id.btn_revoke_access);
                
                User user = AppController.getInstance().getPrefManager().getUser();
                txtName.setText(user.getName());
                txtEmail.setText(user.getEmail());
                btnSignOut.setOnClickListener(this);
                btnRevokeAccess.setOnClickListener(this);
        }
        
        @Override
        public void onClick(View view) {
                switch (view.getId()) {
                        case R.id.btn_sign_out:
                                showProgressDialog("Please wait...");
                                signOut();
                                break;
                        case R.id.btn_revoke_access:
                                showProgressDialog("Please wait...");
                                revokeAccess();
                                break;
                }
        }
        
        @Override
        public void onConnectionFailed(@NonNull ConnectionResult connectionResult) {
                // do something here if google sign in failed
                // e.g. create alert dialog or toast
        }
        
        private void showProgressDialog(String msg) {
                if (mProgressDialog != null)
                        mProgressDialog = null;

                mProgressDialog = new ProgressDialog(Home.this);
                mProgressDialog.setMessage(msg);
                mProgressDialog.setCancelable(false);
                mProgressDialog.show();
        }
        
        private void hideProgressDialog() {
                if (mProgressDialog != null && mProgressDialog.isShowing()) {
                        mProgressDialog.cancel();
                }
        }
        
        private void exitHome() {
                AppController.getInstance().getPrefManager().clear();
                hideProgressDialog();
                Intent intent = new Intent(Home.this, Login.class);
                intent.setFlags(Intent.FLAG_ACTIVITY_NEW_TASK | Intent.FLAG_ACTIVITY_CLEAR_TASK);
                startActivity(intent);
        }
        
        private void signOut() {
                Auth.GoogleSignInApi.signOut(mGoogleApiClient).setResultCallback(
                        new ResultCallback<Status>() {
                                @Override
                                public void onResult(Status status) {
                                        exitHome();
                                }
                        });
         }
        
        private void revokeAccess() {
                Auth.GoogleSignInApi.revokeAccess(mGoogleApiClient).setResultCallback(
                        new ResultCallback<Status>() {
                                @Override
                                public void onResult(Status status) {
                                        exitHome();
                                }
                        });
        }
}`;
