import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import pufferfish from 'assets/png/fishing-game/pufferfish.png';
import GameCarousel from './GameCarousel';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '4em',
  },
  pufferfish: {
    height: '17em',
    width: 'auto',
  },
  title: {
    fontWeight: 700,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  peekContainer: {
    marginRight: '4em',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
}));

const GamePeek = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justify="center"
        className={styles.root}
      >
        <Grid item className={styles.peekContainer}>
          <Grid container direction="column" alignItems="center">
            <Grid item>
              <Typography variant="h4" className={styles.title}>
                What&apos;s inside the game?
              </Typography>
            </Grid>
            <Grid item>
              <img
                src={pufferfish}
                alt="Puffer Fish"
                className={styles.pufferfish}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <GameCarousel />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default GamePeek;
