import React from 'react';
import {
  Avatar,
  Chip,
  Fade,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import myImage from 'assets/jpg/profile.jpg';
import { useTheme } from '@material-ui/styles';
import { ClickableLink } from 'pages/Blogs/components/helper';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '12vh',
    marginBottom: '4vh',
    [theme.breakpoints.down('md')]: {
      marginTop: '8vh',
      marginBottom: '2vh',
    },
  },
  avatar: {
    height: '20em',
    width: '20em',
    [theme.breakpoints.only('md')]: {
      height: '15em',
      width: '15em',
      marginBottom: '2em',
    },
    [theme.breakpoints.only('sm')]: {
      height: '10em',
      width: '10em',
      marginBottom: '2em',
    },
    [theme.breakpoints.only('xs')]: {
      height: '8em',
      width: '8em',
      marginBottom: '2em',
    },
  },
  content: {
    maxWidth: '40em',
    marginLeft: '4em',
    [theme.breakpoints.only('md')]: {
      maxWidth: '30em',
      minHeight: '22em',
      marginLeft: 0,
    },
    [theme.breakpoints.only('sm')]: {
      maxWidth: '30em',
      minHeight: '25em',
      marginLeft: 0,
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '18em',
      minHeight: '20em',
      marginLeft: 0,
    },
  },
  textAlign: {
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
  },
  chips: {
    marginBottom: '1em',
    marginTop: '1em',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '2em',
      marginTop: '2em',
    },
  },
}));

const AboutMe = () => {
  const styles = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <React.Fragment>
      <Grid
        container
        direction={mdDown ? 'column' : 'row'}
        justify="center"
        alignItems="center"
        className={styles.root}
      >
        <Grid item>
          <Fade in timeout={1000}>
            <Avatar
              src={myImage}
              alt="My Profile Pic"
              className={styles.avatar}
            />
          </Fade>
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="flex-start"
          spacing={2}
          className={styles.content}
        >
          <Grid item>
            <Typography variant="h4" className={styles.textAlign}>
              About Me
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="subtitle1"
              paragraph
              className={styles.textAlign}
            >
              I started my career as a full-time college instructor at Asia
              Pacific College. I taught several major courses such as computer
              programming, logic circuits, micro controllers and embedded
              systems. While in an academic career, I also extended my learnings
              in Android app and game development. After 2 years of experience
              in software development, I took international certifications to
              become an Android certified developer and the 2nd Android
              certified trainer in the Philippines licensed by{' '}
              <ClickableLink
                href="https://home.pearsonvue.com/androidatc"
                label="Pearson VUE"
              />
              .
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              className={styles.textAlign}
            >
              I have published several Android games in the Google playstore.
              Using App Store Optimization techniques, I was able to rank my
              game in the Android market. One of my game,{' '}
              <ClickableLink
                href="https://play.google.com/store/apps/details?id=com.mcua.fishing.game"
                label="Fish Games For Kids | Trawling Penguin Games"
              />{' '}
              is currently getting an average of 100+ daily installations.
            </Typography>
            <Typography
              variant="subtitle1"
              paragraph
              className={styles.textAlign}
            >
              Having a strong foundation in Android development through years of
              experience in professional career and freelancing, I decided to
              extend my skills in iOS app development, hybrid app development,
              front-end and back-end web development.
            </Typography>
            <Grid
              item
              container
              spacing={1}
              justify={mdDown ? 'center' : undefined}
              alignItems={mdDown ? 'center' : undefined}
              className={styles.chips}
            >
              <Grid item>
                <Chip
                  label="Android Developer"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Chip
                  label="iOS Developer"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Chip
                  label="Web Developer"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Chip
                  label="Game Developer"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
              <Grid item>
                <Chip
                  label="Educator & Trainer"
                  variant="outlined"
                  color="primary"
                />
              </Grid>
              {/* <Grid item>
                <Chip
                  label="Fiance of Katherine Nguyen"
                  variant="outlined"
                  color="primary"
                />
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AboutMe;
