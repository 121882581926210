import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Dialog,
  IconButton,
  Slide,
  Grid,
  Toolbar,
  AppBar,
  Fade,
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import AppHeaderLogo from './AppHeaderLogo';
import AppHeaderMenuButton from './AppHeaderMenuButton';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
    paddingLeft: '4em',
    paddingRight: '4em',
    paddingTop: '2em',
    paddingBottom: '2em',
    [theme.breakpoints.only('md')]: {
      padding: '2em',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '2em',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '1.5em',
    },
  },
  divider: {
    backgroundColor: '#FFFFFF',
    height: '16em',
    width: '0.25em',
    borderRadius: '2em',
    marginLeft: '1.9em',
    marginRight: '2em',
    marginTop: '1em',
    marginBottom: '1em',
    [theme.breakpoints.only('xs')]: {
      marginLeft: '1.7em',
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const AppHeaderMenuDialog = ({ open, handleClose }) => {
  const styles = useStyles();
  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: '#000000DD',
          boxShadow: 'none',
        },
      }}
    >
      <AppBar position="fixed" className={styles.appBar}>
        <Toolbar disableGutters className={styles.toolbar}>
          <AppHeaderLogo light />
          <Grid item container justify="flex-end">
            <Grid item>
              <IconButton onClick={handleClose} aria-label="close">
                <CloseIcon style={{ fill: 'white' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
        <Fade in timeout={2000}>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="center"
          >
            <Grid item>
              <div className={styles.divider} />
            </Grid>
            <Grid item>
              <Grid item container direction="column">
                <AppHeaderMenuButton
                  label="Home"
                  path="/home"
                  extraHandler={handleClose}
                />
                <AppHeaderMenuButton
                  label="Profile"
                  path="/profile"
                  extraHandler={handleClose}
                />
                <AppHeaderMenuButton
                  label="Games"
                  path="/games"
                  extraHandler={handleClose}
                />
                <AppHeaderMenuButton
                  label="Blogs"
                  path="/blogs"
                  extraHandler={handleClose}
                />
                {/* <AppHeaderMenuButton
                  label="Connect"
                  path="/connect"
                  extraHandler={handleClose}
                /> */}
              </Grid>
            </Grid>
          </Grid>
        </Fade>
      </AppBar>
    </Dialog>
  );
};

AppHeaderMenuDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AppHeaderMenuDialog;
