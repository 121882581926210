import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '4em',
    marginBottom: '4em',
    [theme.breakpoints.only('md')]: {
      marginTop: '3em',
      marginBottom: '3em',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: '2em',
      marginBottom: '2em',
    },
  },
  title: {
    fontWeight: 300,
  },
  caption: {
    maxWidth: '23em',
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: '23em',
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '23em',
      fontSize: '1.4rem',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '23em',
      fontSize: '1.2rem',
    },
  },
}));

const BlogsTitle = () => {
  const styles = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        alignItems={mdDown ? 'center' : undefined}
        spacing={2}
        className={styles.root}
      >
        <Grid item>
          <Typography variant="h6" className={styles.title}>
            Blogs
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" className={styles.caption}>
            Latest trends in mobile/web app development. Food and Travel for
            lifestyle.
          </Typography>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BlogsTitle;
