export const step1 = `dependencies {
        compile 'com.github.bumptech.glide:glide:3.7.0'
        ...
}`;

export const step2 = `<uses-permission android:name="android.permission.INTERNET"/>`;

export const step3 = `public class Feed {
        private String name, location, imageUrl, description, timeStamp;
        public Feed(String name, String location, String imageUrl, String description, String timeStamp) {
                this.name = name;     
                this.location = location;
                this.imageUrl = imageUrl;
                this.description = description;
                this.timeStamp = timeStamp;
        }
        
        public String getName() {
                return name;
        }
        
        public void setName(String name) {
                this.name = name;
        }
        
        public String getLocation() {
                return location;
        }
        
        public void setLocation(String location) {
                this.location = location;
        }  
        
        public String getImageUrl() {
                return imageUrl;
        }
        
        public void setImageUrl(String imageUrl) {
                this.imageUrl = imageUrl;
        }
        
        public String getDescription() {
                return description;
        }
        
        public void setDescription(String description) {
                this.description = description;
        }
        
        public String getTimeStamp() {
                return timeStamp;
        }
        
        public void setTimeStamp(String timeStamp) {
                this.timeStamp = timeStamp;
        }
}`;

export const step4 = `<?xml version="1.0" encoding="utf-8"?>
<LinearLayout xmlns:android="http://schemas.android.com/apk/res/android"
android:layout_width="match_parent"
android:layout_height="wrap_content"
android:orientation="vertical">

        <android.support.v7.widget.CardView
                android:id="@+id/card_view"
                android:layout_width="match_parent"
                android:layout_height="wrap_content">
                
                <RelativeLayout
                        android:layout_width="match_parent"
                        android:layout_height="wrap_content"
                        android:paddingBottom="10dp"
                        android:paddingTop="10dp">

                        <TextView
                                android:id="@+id/cardView_name"
                                android:layout_width="wrap_content"
                                android:layout_height="wrap_content"
                                android:layout_marginLeft="16dp"
                                android:layout_marginStart="16dp"
                                android:singleLine="true"
                                android:text="androidprimeinfo"
                                android:textColor="@android:color/black"
                                android:textStyle="bold"/>

                        <TextView
                                android:id="@+id/cardView_location"
                                android:layout_width="wrap_content"
                                android:layout_height="wrap_content"
                                android:layout_alignLeft="@+id/cardView_name"
                                android:layout_below="@+id/cardView_name"
                                android:singleLine="true"
                                android:text="Metro Manila, Philippines"
                                android:textColor="@android:color/black"/>

                        <ImageView
                                android:id="@+id/cardView_dots"
                                android:layout_width="20dp"
                                android:layout_height="20dp"
                                android:layout_alignParentEnd="true"
                                android:layout_alignParentRight="true"
                                android:layout_marginEnd="16dp"
                                android:layout_marginRight="16dp"
                                android:layout_marginTop="5dp"
                                android:src="@drawable/icon_dots"/>     

                        <ImageView
                                android:id="@+id/cardView_image"
                                android:layout_width="match_parent"
                                android:layout_height="wrap_content"
                                android:layout_below="@+id/cardView_location"
                                android:layout_marginBottom="10dp"
                                android:layout_marginTop="10dp"
                                android:adjustViewBounds="true"
                                android:scaleType="fitXY"
                                android:src="@drawable/sample_image"/>

                        <ImageView
                                android:id="@+id/cardView_heart"
                                android:layout_width="20dp"
                                android:layout_height="20dp"
                                android:layout_below="@id/cardView_image"
                                android:layout_marginLeft="16dp"
                                android:src="@drawable/icon_heart"/>

                        <ImageView
                                android:id="@+id/cardView_comment"
                                android:layout_width="20dp"
                                android:layout_height="20dp"
                                android:layout_below="@id/cardView_image"
                                android:layout_marginLeft="16dp"
                                android:layout_toRightOf="@+id/cardView_heart"
                                android:src="@drawable/icon_comment"/>

                        <ImageView
                                android:id="@+id/cardView_send"
                                android:layout_width="20dp"
                                android:layout_height="20dp"
                                android:layout_below="@id/cardView_image"
                                android:layout_marginLeft="16dp"
                                android:layout_toRightOf="@+id/cardView_comment"
                                android:src="@drawable/icon_send"/>

                        <ImageView
                                android:id="@+id/cardView_bookmark"
                                android:layout_width="20dp"
                                android:layout_height="20dp"
                                android:layout_alignParentEnd="true"
                                android:layout_alignParentRight="true"
                                android:layout_below="@+id/cardView_image"
                                android:layout_marginRight="16dp"
                                android:src="@drawable/icon_bookmark"/>

                        <View
                                android:id="@+id/divider"
                                android:layout_width="match_parent"
                                android:layout_height="0.5dp"
                                android:layout_alignParentLeft="true"
                                android:layout_alignParentStart="true"
                                android:layout_below="@+id/cardView_heart"
                                android:layout_marginBottom="8dp"
                                android:layout_marginLeft="16dp"
                                android:layout_marginRight="16dp"
                                android:layout_marginTop="8dp"
                                android:background="#BDBDBD"/>

                        <TextView
                                android:id="@+id/cardView_description"
                                android:layout_width="match_parent"
                                android:layout_height="wrap_content"
                                android:layout_below="@+id/divider"
                                android:layout_marginLeft="16dp"
                                android:layout_marginRight="16dp"
                                android:text="Write your description here..."
                                android:textColor="@android:color/black"/>

                        <TextView
                                android:id="@+id/cardView_timestamp"
                                android:layout_width="match_parent"
                                android:layout_height="wrap_content"
                                android:layout_below="@+id/cardView_description"
                                android:layout_marginLeft="16dp"
                                android:layout_marginRight="16dp"
                                android:layout_marginTop="8dp"
                                android:text="26 MINUTES AGO"
                                android:textSize="9sp"/>

                </RelativeLayout>

        </android.support.v7.widget.CardView>

</LinearLayout>`;

export const step5 = `public class FeedAdapter extends RecyclerView.Adapter<FeedAdapter.MyViewHolder> {
        private Context mContext;
        private List<Feed> feedList;

        public class MyViewHolder extends RecyclerView.ViewHolder {
                public TextView name, location, description, timeStamp;
                public ImageView image;
                public MyViewHolder(View view) {
                        super(view);
                        name = (TextView) view.findViewById(R.id.cardView_name);
                        location = (TextView) view.findViewById(R.id.cardView_location);
                        description = (TextView) view.findViewById(R.id.cardView_description);
                        timeStamp = (TextView) view.findViewById(R.id.cardView_timestamp);
                        image = (ImageView) view.findViewById(R.id.cardView_image);
                }
        }
        
        public FeedAdapter(Context mContext, List<Feed> feedList) {
                this.mContext = mContext;
                this.feedList = feedList;
        }
        
        @Override
        public MyViewHolder onCreateViewHolder(ViewGroup parent, int viewType) {
                // inflate new view when we create new items in our recyclerview
                View itemView = LayoutInflater.from(parent.getContext())
                .inflate(R.layout.cardview_feed, parent, false);
                return new MyViewHolder(itemView);
        }
        
        @Override
        public void onBindViewHolder(MyViewHolder holder, int position) {
                // sets all the text and image resources when the card view is bind in our adapter
                final Feed feed = feedList.get(position);
                holder.name.setText(feed.getName());
                holder.location.setText(feed.getLocation());
                holder.description.setText(feed.getDescription());
                holder.timeStamp.setText(feed.getTimeStamp());
                // this is the code for the Glide image library
                Glide.with(mContext)
                .load(feed.getImageUrl())
                .override(1280, 720)
                .fitCenter()
                .priority(Priority.LOW)
                .into(holder.image);
        }
        
        @Override
        public int getItemCount() {
                return feedList.size();
        }
        
        @Override
        public long getItemId(int position) {
                return super.getItemId(position);
        }
}
`;

export const step6 = `<?xml version="1.0" encoding="utf-8"?>
<RelativeLayout
        xmlns:android="http://schemas.android.com/apk/res/android"
        xmlns:tools="http://schemas.android.com/tools"
        android:id="@+id/activity_main"
        android:layout_width="match_parent"
        android:layout_height="match_parent"
        tools:context="info.androidprime.instagramfeed.activity.MainActivity">

                <android.support.v7.widget.RecyclerView
                        android:id="@+id/recycler_view"
                        android:layout_width="match_parent"
                        android:layout_height="match_parent"
                        android:clipToPadding="false"
                        android:scrollbars="vertical"/>

</RelativeLayout>`;

export const step7 = `public class MainActivity extends AppCompatActivity {
        private RecyclerView recyclerView;
        private RecyclerView.LayoutManager mLayoutManager;
        private FeedAdapter adapter;
        private List<Feed> feedList;
        
        @Override
        protected void onCreate(Bundle savedInstanceState) {
                super.onCreate(savedInstanceState);
                setContentView(R.layout.activity_main);
                feedList = new ArrayList<>();
                adapter = new FeedAdapter(this, feedList);

                // sets our layout manager and recycler view
                mLayoutManager = new LinearLayoutManager(MainActivity.this);
                recyclerView = (RecyclerView) findViewById(R.id.recycler_view);
                recyclerView.setLayoutManager(mLayoutManager);
                recyclerView.setItemAnimator(new DefaultItemAnimator());
                recyclerView.setAdapter(adapter);

                // create a new object feed to be inserted in our adapter.
                Feed feed = new Feed("androidprime",
                "Metro Manila, Philippines",
                "http://androidprime.info/android/images/Tutorial5.jpg",
                "In this tutorial, I’m going to teach you how to use Android Context Menu and " +
                "GridView. We’re going to creating a simple wallpaper application that " +
                "will allow the users to choose and apply a wallpaper in our gallery.",
                "3 DAYS AGO");
                feedList.add(0, feed);

                feed = new Feed("androidprime",
                "Metro Manila, Philippines",
                "http://androidprime.info/android/images/Tutorial6.jpg",
                "Remember the old Facebook Messenger app? In this tutorial, I’m going to teach " +
                "you how to create a Facebook Messenger like layout using Android Design " +
                "library, Fragments and ViewPager. I’ll also teach you how to support " +
                "multiple screens by adding different sizes of drawables in your project.",
                "1 DAY AGO");
                feedList.add(0, feed);

                feed = new Feed("androidprime",
                "Metro Manila, Philippines",
                "http://androidprime.info/android/images/Tutorial7.jpg",
                "Being able to save data locally  is one of the most important feature of an app. " +
                "In this tutorial, I’m going to teach you how to do that by using Android " +
                "SQLite Database. I’ll also teach you how to apply basic material design " +
                "in your application.",
                "10 HOURS AGO");
                feedList.add(0, feed);

                feed = new Feed("androidprime",
                "Metro Manila, Philippines",
                "http://androidprime.info/android/images/Tutorial8.jpg",
                "The quickest and easiest way of integrating user registration in your application " +
                "is through existing social networks such as Google, Facebook, VK and etc.. " +
                "In this tutorial, I’m going to teach you how to implement Google Plus " +
                "Sign In using Android.",
                "26 MINUTES AGO");
                feedList.add(0, feed);
                
                //call this every time you're going to add new items in your recycler view
                adapter.notifyDataSetChanged();
        }
}`;
