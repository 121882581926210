import React from 'react';

import VideoPlayer from 'components/VideoPlayer';
import GameTitle from './components/GameTitle';
import GameDescription from './components/GameDescription';
import GamePeek from './components/GamePeek';

const FishingGame = () => {
  return (
    <React.Fragment>
      <GameTitle />
      <GameDescription />
      <GamePeek />
      <VideoPlayer url="https://www.youtube.com/watch?v=IzmMV-l4tN4&t=8s" />
    </React.Fragment>
  );
};

export default FishingGame;
