import React from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import AppHeaderLogo from 'components/AppHeader/components/AppHeaderLogo';

import facebook from 'assets/svg/facebook.svg';
import instagram from 'assets/svg/instagram.svg';
import linkedin from 'assets/svg/linkedin.svg';

const useStyles = makeStyles(() => ({
  headerLogoContainer: {
    paddingBottom: '1em',
  },
  logo: {
    width: 'auto',
    height: '2em',
    opacity: 0.85,
  },
  connect: {
    color: 'white',
    marginLeft: '1em',
    maxWidth: '18em',
    fontWeight: 300,
    fontSize: '0.8rem',
  },
}));

const SocialLinks = ({ showLogo }) => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Grid item>
        {showLogo ? (
          <Grid item className={styles.headerLogoContainer}>
            <AppHeaderLogo light />
          </Grid>
        ) : null}
        <Typography className={styles.connect}>
          You can connect with me on the following social media platforms. You
          can also view my profile and credentials on LinkedIn.
        </Typography>
        <Grid item container>
          <IconButton aria-label="close">
            <a
              style={{ display: 'table-cell' }}
              href="https://www.facebook.com/markreyescua"
              target="_blank"
              rel="noreferrer"
            >
              <img src={facebook} alt="Facebook Link" className={styles.logo} />
            </a>
          </IconButton>
          <IconButton aria-label="close">
            <a
              style={{ display: 'table-cell' }}
              href="https://www.instagram.com/itsmcua"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={instagram}
                alt="Instagram Link"
                className={styles.logo}
              />
            </a>
          </IconButton>
          <IconButton aria-label="close">
            <a
              style={{ display: 'table-cell' }}
              href="https://www.linkedin.com/in/mark-edison-cua-69b0145a/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={linkedin}
                alt="Linked-in Link"
                className={styles.logo}
              />
            </a>
          </IconButton>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

SocialLinks.defaultProps = {
  showLogo: false,
};

SocialLinks.propTypes = {
  showLogo: PropTypes.bool,
};

export default SocialLinks;
