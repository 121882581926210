import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles } from '@material-ui/core';

import screenshot1 from 'assets/png/fishing-game/1.png';
import screenshot2 from 'assets/png/fishing-game/2.png';
import screenshot3 from 'assets/png/fishing-game/3.png';
import screenshot4 from 'assets/png/fishing-game/4.png';
import screenshot5 from 'assets/png/fishing-game/5.png';
import screenshot6 from 'assets/png/fishing-game/6.png';
import Carousel from 'react-material-ui-carousel';

const images = [
  screenshot1,
  screenshot2,
  screenshot3,
  screenshot4,
  screenshot5,
  screenshot6,
];

const useStyles = makeStyles((theme) => ({
  images: {
    height: '20em',
    width: 'auto',
    minWidth: '30em',
    marginTop: '1em',
    marginBottom: '1em',
    [theme.breakpoints.only('xs')]: {
      height: '14em',
      minWidth: '18em',
    },
  },
}));

const ScreenShotImage = ({ image }) => {
  const styles = useStyles();
  return <img src={image} alt="Screenshot" className={styles.images} />;
};

ScreenShotImage.propTypes = {
  image: PropTypes.string.isRequired,
};

const GameCarousel = () => {
  return (
    <React.Fragment>
      <Grid container>
        <Carousel
          autoPlay
          timer={4000}
          animation="slide"
          indicators
          navButtonsAlwaysVisible
        >
          {images.map((item, index) => {
            return <ScreenShotImage image={item} key={index} />;
          })}
        </Carousel>
      </Grid>
    </React.Fragment>
  );
};

export default GameCarousel;
