import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';

import {
  blogStyles,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output1 from 'assets/png/blogs/T4-output1.png';
import output2 from 'assets/png/blogs/T4-output2.png';
import {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
} from './codebase';

const BlogsAndroidRegistrationApp3 = () => {
  const styles = blogStyles();
  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />

      <Typography paragraph>asasdads</Typography>

      <CodeSnippet codes={step1} />
      <CodeSnippet codes={step2} />
      <CodeSnippet codes={step3} />
      <CodeSnippet codes={step4} />
      <CodeSnippet codes={step5} />
      <CodeSnippet codes={step6} />
      <CodeSnippet codes={step7} />
      <CodeSnippet codes={step8} />
      <CodeSnippet codes={step9} />

      <ScreenShot source={output1} />
      <ScreenShot source={output2} />
    </BlogContentLayout>
  );
};

export default BlogsAndroidRegistrationApp3;
