import React from 'react';
import { Grid, makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import androidLogo from 'assets/png/android-logo-only.png';
import swiftLogo from 'assets/png/swift-logo-only.png';
import angularLogo from 'assets/png/angular-logo.png';
import reactLogo from 'assets/png/react-js-logo.png';
import nodeJsLogo from 'assets/png/node-js-logo-only.png';
import ionicLogo from 'assets/png/ionic-logo.png';

import SkillCard from './SkillCard';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '6em',
    paddingBottom: '15em',
    background: '#313D3F',
    [theme.breakpoints.only('md')]: {
      padding: '4em',
      paddingBottom: '12em',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
      paddingBottom: '8em',
    },
  },
  title: {
    color: 'white',
    textAlign: 'center',
  },
  paper: {},
  logo: {
    height: ' 8em',
    width: 'auto',
  },
}));

const MySkills = () => {
  const styles = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const xsSpace = 12;
  const smSpace = 8;
  const mdSpace = 9;
  const lgSpace = 6;
  const xlSpace = 4;

  return (
    <React.Fragment>
      <Grid container direction="column" className={styles.root}>
        <Grid item>
          <Typography variant="h4" className={styles.title}>
            What technologies do I use?
          </Typography>
        </Grid>
        <Grid
          item
          container
          direction={mdDown ? 'column' : 'row'}
          justify="center"
          alignItems={mdDown ? 'center' : 'stretch'}
          spacing={6}
          style={{ marginTop: '2em' }}
        >
          <Grid
            item
            xs={xsSpace}
            sm={smSpace}
            md={mdSpace}
            lg={lgSpace}
            xl={xlSpace}
          >
            <SkillCard
              logo={androidLogo}
              title="Android - Java/Kotlin"
              content="8 years of solid experience in Android app development with Android Studio and Java/Kotlin programming languages. 
              Building responsive apps using MVP/MVVM design pattern architecture. Published several apps in the Google Playstore."
            />
          </Grid>

          <Grid
            item
            xs={xsSpace}
            sm={smSpace}
            md={mdSpace}
            lg={lgSpace}
            xl={xlSpace}
          >
            <SkillCard
              logo={swiftLogo}
              title="iOS - Swift"
              content="Creating iOS application using XCode for mac. 
              Using swift as primary programming language, MVC for design pattern architecture and storyboards to create beautiful user interface."
            />
          </Grid>

          <Grid
            item
            xs={xsSpace}
            sm={smSpace}
            md={mdSpace}
            lg={lgSpace}
            xl={xlSpace}
          >
            <SkillCard
              logo={ionicLogo}
              title="Ionic - Hybrid"
              content="Building cross-platform apps using React/Angular framework to perform and run fast on all of the latest mobile devices."
            />
          </Grid>

          <Grid
            item
            xs={xsSpace}
            sm={smSpace}
            md={mdSpace}
            lg={lgSpace}
            xl={xlSpace}
          >
            <SkillCard
              logo={angularLogo}
              title="Angular - Web"
              content="Building web, mobile web, native mobiel and native desktops apps using Angular framework. 
              Creating beautiful design using Material design theme."
            />
          </Grid>

          <Grid
            item
            xs={xsSpace}
            sm={smSpace}
            md={mdSpace}
            lg={lgSpace}
            xl={xlSpace}
          >
            <SkillCard
              logo={reactLogo}
              title="React Native - Web"
              content="Building web, mobile web, native mobiel and native desktops apps using React framework. 
              Creating beautiful design using Material design theme."
            />
          </Grid>

          <Grid
            item
            xs={xsSpace}
            sm={smSpace}
            md={mdSpace}
            lg={lgSpace}
            xl={xlSpace}
          >
            <SkillCard
              logo={nodeJsLogo}
              title="Node JS"
              content="Building traditional back-end API services using Node JS."
            />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MySkills;
