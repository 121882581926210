import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';

import {
  blogStyles,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output from 'assets/png/blogs/T6-output.png';
import {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
} from './codebase';

const BlogsAndroidCustomTabs = () => {
  const styles = blogStyles();
  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />

      <Typography paragraph>
        Remember the old Facebook Messenger app? In this tutorial, I&apos;m
        going to teach you how to create a Facebook Messenger like layout using
        Android Design library, Fragments and ViewPager. I&apos;ll also teach
        you how to support multiple screens by adding different sizes of
        drawables in your project.
      </Typography>
      <Typography paragraph>
        <b>What are fragments?</b>
      </Typography>
      <Typography paragraph>
        A fragment is a portion of the user interface in an activity. They are
        available since Android 3.0. They improve the UI reusability and allows
        the developer to create a multi-pane UI by combining multiple fragments
        into a single activity or using the same fragment in multiple
        activities.
      </Typography>
      <Typography paragraph>
        You can add several fragments to an activity to build UI. In our
        tutorial, we&apos;re going to use fragments as the content of our
        messenger tabs. In the old version of the Facebook messenger tab, we
        have the tabs calls, friend list, group chats, profile, and recent
        chats.
      </Typography>
      <Typography paragraph>
        <b>Characteristics of Fragments</b>
        <li>Can be added or removed to an activity while/during run-time.</li>
        <li>Have their own layout with their own lifecycle callbacks.</li>
        <li>Can be used in different activities.</li>
      </Typography>
      <Typography paragraph>
        We&apos;re also going to use the Android support design library. By
        default, it is not included when you create a new Android Studio
        project. You can include it by adding this line in your build.gradle
        under dependencies.
      </Typography>
      <CodeSnippet codes={step1} />

      <Typography paragraph>
        Make sure that the version of the appcompat library is the same as the
        version of you design library. You can find build.gradle here. Since
        we&apos;re going to use images in our tabs, It is very important to
        adjust the drawable we&apos;re going to use depending on the
        mobile&apos;s screen size. We can do this by creating multiple sizes of
        drawables just like this.
      </Typography>

      <Typography paragraph component="span">
        We need to create a new drawable directory where we&apos;ll place the
        assets. You can do this by:
        <ul>1. Right click on res folder</ul>
        <ul>2. Then choose new then Android resource directory</ul>
        <ul>
          3. Choose the Resource type as drawable then edit the directory name.
          Create three new directories namely drawable-hdpi, drawable-xhdpi and
          drawable-xxhdpi
        </ul>
      </Typography>
      <Typography paragraph>
        Now copy the all icons I provided in its proper directory. You can
        download it here. Let&apos;s start by creating our project&apos;s other
        classes. We need five fragments for the tabs. To make the structure of
        our project neat, let&apos;s create a new package for the fragments.
        Just follow the structure below. As we talked about fragments earlier,
        they need to have their own layout. So create an xml layout file for
        each fragments. You should have something like this.
      </Typography>

      <CodeSnippet codes={step2} />

      <Typography paragraph>
        Just change the text for every fragment to distinguish it from others.
        Now open the fragment classes and copy the codes below.
      </Typography>
      <CodeSnippet codes={step3} />

      <Typography paragraph>
        Now open colors.xml under <b>res/values</b> folder then change the
        default color code of the following.
      </Typography>
      <CodeSnippet codes={step4} />

      <Typography paragraph>
        Open <b>styles.xml</b> and add the following code. This will remove the
        action bar in our main activity.
      </Typography>
      <CodeSnippet codes={step5} />

      <Typography paragraph>
        Create a new drawable named <b>button_rounded.xml</b>.
      </Typography>
      <CodeSnippet codes={step7} />

      <Typography paragraph>
        This drawable will be the background of our search button. Let&apos;s
        now work on the layout in our <b>activity_main.xml</b>. This will make
        your layout look like the old version of Facebook Messenger app.
      </Typography>
      <CodeSnippet codes={step6} />

      <Typography paragraph>
        Finally, open <b>MainActivity.java</b> then copy and paste the codes
        provided below.
      </Typography>
      <CodeSnippet codes={step8} />

      <Typography paragraph>
        To make the ViewPager working, we need to create an adapter for it and
        by extending FragmentPagerAdapter. We&apos;ll name that as
        ViewPagerAdapter. Now you can run and test you app if it is working. The
        final output of this tutorial should be like this.
      </Typography>
      <ScreenShot source={output} />
      <Divider className={styles.divider} />
    </BlogContentLayout>
  );
};

export default BlogsAndroidCustomTabs;
