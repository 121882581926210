import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { selectCurrentPage } from 'selectors/resources';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useSelector } from 'react-redux';

const StyledTabs = withStyles((theme) => ({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: theme.palette.accent.main,
    },
    width: 80,
  },
}))((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.text.primary,
    fontSize: '1rem',
    fontWeight: 700,
    '&:focus': {
      opacity: 1,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
      minWidth: 'min-content',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent',
  },
}));

const AppHeaderTabs = () => {
  const styles = useStyles();
  const history = useHistory();
  const [value, setValue] = useState(0);
  const currentPage = useSelector(selectCurrentPage);

  useEffect(() => {
    switch (currentPage) {
      case '/home':
        setValue(0);
        break;
      case '/profile':
        setValue(1);
        break;
      case '/games':
        setValue(2);
        break;
      case '/blogs':
        setValue(3);
        break;
      default:
        if (currentPage.indexOf('/blogs') > -1) {
          setValue(3);
        }
        break;
    }
  }, [currentPage]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    switch (newValue) {
      case 0:
        history.push('/home');
        break;
      case 1:
        history.push('/profile');
        break;
      case 2:
        history.push('/games');
        break;
      case 3:
        history.push('/blogs');
        break;
      case 4:
        history.push('/connect');
        break;
      default:
        history.push('/home');
        break;
    }
  };

  return (
    <div className={styles.root}>
      <StyledTabs
        value={value}
        onChange={handleChange}
        aria-label="styled tabs"
      >
        <StyledTab label="Home" />
        <StyledTab label="Profile" />
        <StyledTab label="Games" />
        <StyledTab label="Blogs" />
        {/* <StyledTab label="Connect" /> */}
      </StyledTabs>
    </div>
  );
};

export default AppHeaderTabs;
