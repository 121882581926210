export const step1 = `dependencies {
        ...        
        compile 'com.android.support:design:25.0.1'
}`;

export const step2 = `public class MyDBAdapter {    
        private Context mContext;    
        private MyDBHelper myDBHelper;    
        private SQLiteDatabase db;    
        private String DATABASE_NAME = "registration";    
        private int DATABASE_VERSION = 1; // change this if you want to change your database structure    
        
        public MyDBAdapter(Context context) {        
                this.mContext = context;        
                myDBHelper = new MyDBHelper(context, DATABASE_NAME, null, DATABASE_VERSION);    
        }    
        
        public void open() {        
                db = myDBHelper.getWritableDatabase();    
        }    
        
        public void register(String name, String email, String contact) {        
                // content values are used to store a set of values that will be inserted in a table        
                // in our application, we're going to insert name, email and contact        
                ContentValues cv = new ContentValues();        
                cv.put("name", name);        
                cv.put("email", email);        
                cv.put("contact", contact);        
                db.insert("registration", null, cv);    
        }    
        
        public ArrayList<String> getAllRegistrations() {        
                // this method will get all the records in the table registration        
                // then it will store all the data in an arraylist        
                ArrayList<String> registrations = new ArrayList<String>();        
                Cursor cursor = db.query("registration", null, null, null, null, null, null);        
                if (cursor != null && cursor.moveToFirst()) {            
                        do {                
                                //cursor.getString(0) = id                
                                //cursor.getString(1) = name                
                                //cursor.getString(2) = email                
                                //cursor.getString(3) = contact                
                                String record = cursor.getString(1) + " : " + cursor.getString(2);                
                                registrations.add(record);            
                        } while (cursor.moveToNext());        
                }        
                return registrations;    
        }    

        public void deleteAll() {        
                // the name of the methods explains it all        
                db.delete("registration", null, null);    
        }    
        
        public class MyDBHelper extends SQLiteOpenHelper {        
                // this will be our SQLite inner class adapter        
                public MyDBHelper(Context context, String name, CursorFactory factory, int version) {            
                        super(context, name, factory, version);        
                }        
                
                @Override        
                public void onCreate(SQLiteDatabase sqLiteDatabase) {            
                        // only run when the database file did not exist and was just created.            
                        String query = "CREATE TABLE registration (" +                    
                        "id integer primary key autoincrement, " +                    
                        "name text, " +                    
                        "email text, " +                    
                        "contact text);";            
                        sqLiteDatabase.execSQL(query);        
                }        
                
                @Override        
                public void onUpgrade(SQLiteDatabase sqLiteDatabase, int i, int i1) {            
                        // only called when the database file exists but the            
                        // stored version number is lower than requested in constructor.            
                        String query = "DROP TABLE IF EXISTS registration;";            
                        sqLiteDatabase.execSQL(query);            
                        onCreate(sqLiteDatabase);        
                }    
        }
}`;

export const step3 = `<?xml version="1.0" encoding="utf-8"?>
        <RelativeLayout    
                xmlns:android="http://schemas.android.com/apk/res/android"    
                xmlns:tools="http://schemas.android.com/tools"    
                android:id="@+id/activity_main"    
                android:layout_width="match_parent"    
                android:layout_height="match_parent"    
                android:paddingBottom="@dimen/activity_vertical_margin"    
                android:paddingLeft="@dimen/activity_horizontal_margin"    
                android:paddingRight="@dimen/activity_horizontal_margin"    
                android:paddingTop="@dimen/activity_vertical_margin"    
                tools:context="info.androidprime.sqliteregistration.MainActivity">    

        <android.support.design.widget.TextInputLayout        
                android:id="@+id/text_input_layout_name"        
                android:layout_width="match_parent"        
                android:layout_height="wrap_content"        
                android:layout_alignParentLeft="true"        
                android:layout_alignParentStart="true"        
                android:layout_alignParentTop="true">        

                <EditText            
                        android:id="@+id/editText_name"            
                        android:layout_width="match_parent"            
                        android:layout_height="wrap_content"            
                        android:hint="* Full Name"            
                        android:imeOptions="actionDone"            
                        android:inputType="textPersonName"            
                        android:maxLength="128"/>    

        </android.support.design.widget.TextInputLayout>  

        <android.support.design.widget.TextInputLayout        
                android:id="@+id/text_input_layout_email"        
                android:layout_width="match_parent"        
                android:layout_height="wrap_content"        
                android:layout_alignParentLeft="true"        
                android:layout_alignParentStart="true"        
                android:layout_below="@+id/text_input_layout_name">  

                <EditText            
                        android:id="@+id/editText_email"            
                        android:layout_width="match_parent"            
                        android:layout_height="wrap_content"            
                        android:hint="* Email Address"            
                        android:imeOptions="actionDone"            
                        android:inputType="textEmailAddress"            
                        android:maxLength="128"/>    

        </android.support.design.widget.TextInputLayout>    
       
        <android.support.design.widget.TextInputLayout        
                android:id="@+id/text_input_layout_contact"        
                android:layout_width="match_parent"       
                android:layout_height="wrap_content"        
                android:layout_alignParentLeft="true"        
                android:layout_alignParentStart="true"        
                android:layout_below="@+id/text_input_layout_email">        

                <EditText            
                        android:id="@+id/editText_contact"
                        android:layout_width="match_parent"
                        android:layout_height="wrap_content"
                        android:hint="* Contact No."
                        android:imeOptions="actionDone"
                        android:inputType="number"
                        android:maxLength="16"/>

        </android.support.design.widget.TextInputLayout>

        <Button
                android:id="@+id/button_sign_up"
                style="@style/Widget.AppCompat.Button.Colored"
                android:layout_width="match_parent"
                android:layout_height="wrap_content"
                android:layout_alignParentLeft="true"
                android:layout_alignParentStart="true"
                android:layout_below="@+id/text_input_layout_contact"
                android:layout_marginTop="16dp"
                android:text="Sign Up"
                android:textAllCaps="false"/>

        <Button
                android:id="@+id/button_clear_database"
                style="@style/Widget.AppCompat.Button.Colored"
                android:layout_width="match_parent"
                android:layout_height="wrap_content"
                android:layout_alignParentLeft="true"
                android:layout_alignParentStart="true"
                android:layout_below="@+id/button_sign_up"
                android:text="Clear Database"
                android:textAllCaps="false"/>

        <ListView
                android:id="@+id/registration_list"
                android:layout_width="match_parent"
                android:layout_height="match_parent"
                android:layout_alignParentBottom="true"
                android:layout_below="@+id/button_clear_database"/>

</RelativeLayout>`;

export const step4 = `public class MainActivity extends AppCompatActivity implements OnClickListener {    
        private MyDBAdapter myDBAdapter;    
        private EditText editTextName, editTextEmail, editTextContact;    
        private TextInputLayout textInputLayoutName, textInputLayoutEmail, textInputLayoutContact;    
        private ListView registrationList;    
        private Button signup, clear;    
        
        @Override    
        protected void onCreate(Bundle savedInstanceState) {
                super.onCreate(savedInstanceState);
                setContentView(R.layout.activity_main);
                //initialize view components
                editTextName = (EditText) findViewById(R.id.editText_name);
                editTextEmail = (EditText) findViewById(R.id.editText_email);
                editTextContact = (EditText) findViewById(R.id.editText_contact);
                textInputLayoutName = (TextInputLayout) findViewById(R.id.text_input_layout_name);
                textInputLayoutEmail = (TextInputLayout) findViewById(R.id.text_input_layout_email);
                textInputLayoutContact = (TextInputLayout) findViewById(R.id.text_input_layout_contact);
                registrationList = (ListView) findViewById(R.id.registration_list);
                signup = (Button) findViewById(R.id.button_sign_up);
                clear = (Button) findViewById(R.id.button_clear_database);
                signup.setOnClickListener(this);
                clear.setOnClickListener(this);
                //set up and open our SQLite adapter
                myDBAdapter = new MyDBAdapter(MainActivity.this);
                myDBAdapter.open();
                //call this method every time you want to update your listview
                updateRegistrationList();
        }

        @Override
        public void onClick(View view) {
                switch (view.getId()) {
                        case R.id.button_sign_up:
                                register();
                                break;
                        case R.id.button_clear_database:
                                //clears the database then update the listview
                                myDBAdapter.deleteAll();
                                updateRegistrationList();
                                break;
                }    
        }    
                
        private void register() {
                String name, email, contact;
                // check if user has name
                if (editTextName.getText().toString().trim().isEmpty()) {
                        //sets an error message in your input layout and adds focus to it
                        textInputLayoutName.setError("Name is required.");
                        requestFocus(editTextName);
                        return;
                } else {            
                        name = editTextName.getText().toString().trim();            
                        textInputLayoutName.setErrorEnabled(false);        
                }        
                // check if user has email        
                if (editTextEmail.getText().toString().trim().isEmpty()) {            
                        textInputLayoutEmail.setError("Email is required.");            
                        requestFocus(editTextEmail);            
                        return;        
                } else {            
                        email = editTextEmail.getText().toString().trim();            
                        textInputLayoutEmail.setErrorEnabled(false);        
                }        
                // check if user has contact        
                if (editTextContact.getText().toString().trim().isEmpty()) {            
                        textInputLayoutContact.setError("Contact no. is required.");            
                        requestFocus(editTextContact);
                        return;
                } else {
                        contact = editTextContact.getText().toString().trim();
                        textInputLayoutContact.setErrorEnabled(false);
                }
                
                myDBAdapter.register(name, email, contact);
                updateRegistrationList();
                
                //clears all input
                editTextName.setText("");
                editTextEmail.setText("");
                editTextContact.setText("");
                requestFocus(editTextName);
        }
        
        private void requestFocus(View view) {
                if (view.requestFocus()) {
                        getWindow().setSoftInputMode(WindowManager.LayoutParams.SOFT_INPUT_STATE_ALWAYS_VISIBLE);
                }    
        }    
        
        private void updateRegistrationList() {
                ArrayList<String> registrations = new ArrayList<>();
                registrations = myDBAdapter.getAllRegistrations();
                final ArrayAdapter<String> adapter = new ArrayAdapter<String>(
                        MainActivity.this, android.R.layout.simple_list_item_1, registrations
                );
                registrationList.setAdapter(adapter);
        }
}
`;
