import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';

import {
  blogStyles,
  ClickableLink,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output from 'assets/png/blogs/T7-output.png';
import { step1, step2, step3, step4 } from './codebase';

const BlogsAndroidSQLite = () => {
  const styles = blogStyles();
  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />

      <Typography paragraph>
        Being able to save data locally is one of the most important feature of
        an app. In this tutorial, I&apos;m going to teach you how to do that by
        using Android SQLite Database. I&apos;ll also teach you how to apply
        basic material design in your application.
      </Typography>

      <Typography paragraph>
        <b>Android SQLite</b>
      </Typography>

      <Typography paragraph>
        Android comes in with a built in local database implementation (SQLite)
        that stores data to a text file on a device. It supports all the
        relational database features and has methods to create, delete, execute
        SQL commands, and perform other common database management tasks. You
        can read more about SQLite here:{' '}
        <ClickableLink href="http://www.sqlite.org/" label="SQLite" />
      </Typography>

      <Typography paragraph>
        <b>Steps to use SQLite in Android</b>
        <li> Create an Adapter class.</li>
        <li>Create an inner private class that extends SQLiteOpenHelper</li>
        <li>Create a class constructor</li>
        <li>Create an inner class constructor</li>
        <li>Override onCreate() and onUpgrade() methods of the inner class</li>
        <li>Create a method that will open your database</li>
        <li>Create you database operations(Delete, Update, Select, Insert).</li>
      </Typography>

      <Typography paragraph>
        <b>What is material design?</b>
      </Typography>

      <Typography paragraph>
        Material design is a comprehensive guide for visual, motion, and
        interaction design across platforms and devices. Android provides the
        following elements for you to build material design apps such as a new
        theme, new widgets, custom shadows and animations and many more. You can
        read more about material design in the official developer site of
        Android.
        <ClickableLink
          href="https://developer.android.com/design/material/index.html"
          label="Material UI"
        />
      </Typography>

      <Typography paragraph>
        Now we&apos;re going to learn both by creating a registration app using
        SQLite and material design. The final output of this tutorial will look
        like this. In order to add material design in you Android project, first
        we need to add this in your <b>build.gradle</b> dependencies. Make sure
        that the version of your design library is the same as the version of
        your appcompat library.
      </Typography>

      <CodeSnippet codes={step1} />
      <Typography paragraph>
        Sync your build.gradle and wait for a while for this will take time
        downloading the library from the repository depending on the speed of
        your internet connection. For the SQLite, we don&apos;t need to add it
        in our dependencies since it&apos;s already a built in feature of
        Android. Now create a new class in your main package folder and name it{' '}
        <b>MyDBAdapter.java</b>. This will serve as the adapter of our SQLite as
        what we have discussed earlier.
      </Typography>
      <Typography paragraph>
        Open <b>MyDBAdapter.java</b> the copy and paste the codes provided
        below. I&apos;ve put some comments so you&apos;ll understand the codes.
      </Typography>
      <CodeSnippet codes={step2} />
      <Typography paragraph>
        Now open activity_main.xml and add the codes below. This will furnish
        the layout of our final output.
      </Typography>
      <CodeSnippet codes={step3} />
      <Typography paragraph>
        Then open <b>MainActivity.java</b> and add the codes provided below.
        Again, I&apos;ve put some comments so you&apos;ll understand the codes
        below.
      </Typography>
      <CodeSnippet codes={step4} />
      <Typography paragraph>
        That&apos;s it! Fast and easy. Now you can run and test you app if it is
        working. The final output of this tutorial should be like this. Now fill
        out all the edit text and click sign up. With the help of SQLite
        database, even you closed the app completely the information of the
        registrants will remain in your application.
      </Typography>

      <ScreenShot source={output} />
      <Divider className={styles.divider} />
    </BlogContentLayout>
  );
};
export default BlogsAndroidSQLite;
