export const step1 = `dependencies {
        ...	
        compile 'com.android.support:design:25.0.1'
}`;

export const step2 = `<?xml version="1.0" encoding="utf-8"?>
<RelativeLayout    
        xmlns:android="http://schemas.android.com/apk/res/android"    
        android:layout_width="match_parent"    
        android:layout_height="match_parent">    

        <TextView        
                android:id="@+id/textView"        
                android:layout_width="wrap_content"       
                android:layout_height="wrap_content"        
                android:layout_centerHorizontal="true"        
                android:layout_centerVertical="true"        
                android:text="Calls"/>

</RelativeLayout>`;

export const step3 = `public class Calls extends Fragment{    
        public Calls() {    
                
        }    
        
        @Override    
        public void onCreate(@Nullable Bundle savedInstanceState) {        
                super.onCreate(savedInstanceState);    
        }  

        @Nullable    
        @Override    
        public View onCreateView(LayoutInflater inflater, @Nullable ViewGroup container,                             
                @Nullable Bundle savedInstanceState) {        
                        return inflater.inflate(R.layout.fragment_calls, container, false);    
                }    
        }

public class FriendList extends Fragment {    
        public FriendList() {    

        }    
        
        @Override    
        public void onCreate(@Nullable Bundle savedInstanceState) {        
                super.onCreate(savedInstanceState);    
        }    
        
        @Nullable    
        @Override    
        public View onCreateView(LayoutInflater inflater, @Nullable ViewGroup container,                             
                @Nullable Bundle savedInstanceState) {        
                        return inflater.inflate(R.layout.fragment_list, container, false);    
                }
        }

public class Groups extends Fragment {    
        public Groups() {    

        }    
        
        @Override    
        public void onCreate(@Nullable Bundle savedInstanceState) {        
                super.onCreate(savedInstanceState);    
        }    
        
        @Nullable    
        @Override    
        public View onCreateView(LayoutInflater inflater, @Nullable ViewGroup container,                             
                @Nullable Bundle savedInstanceState) {        
                        return inflater.inflate(R.layout.fragment_groups, container, false);    
                }
        }

public class Profile extends Fragment {    
        public Profile() {    

        }    
        
        @Override    
        public void onCreate(@Nullable Bundle savedInstanceState) {        
                super.onCreate(savedInstanceState);    
        }    
        
        @Nullable    
        @Override    
        public View onCreateView(LayoutInflater inflater, @Nullable ViewGroup container,                             
                @Nullable Bundle savedInstanceState) {        
                        return inflater.inflate(R.layout.fragment_profile, container, false);    
                }
        }

public class Recent extends Fragment {    
        public Recent() {    

        }    
        
        @Override    
        public void onCreate(@Nullable Bundle savedInstanceState) {        
                super.onCreate(savedInstanceState);    
        }    
        
        @Nullable    
        @Override    
        public View onCreateView(LayoutInflater inflater, @Nullable ViewGroup container,                             
                @Nullable Bundle savedInstanceState) {        
                        return inflater.inflate(R.layout.fragment_recent, container, false);    
                }
        }`;

export const step4 = `<?xml version="1.0" encoding="utf-8"?>
<resources>    
        <!--facebook primary color-->    
        <color name="colorPrimary">#0080FF</color>    
        <color name="colorPrimaryDark">#0040FF</color>    
        <color name="colorAccent">#FF4081</color>
</resources>
`;

export const step5 = `<resources>    
        <!-- Base application theme. -->    
        <style name="AppTheme" parent="Theme.AppCompat.Light.DarkActionBar">        
                <!-- Customize your theme here. -->        
                <item name="windowNoTitle">true</item>        
                <item name="windowActionBar">false</item>        
                <item name="colorPrimary">@color/colorPrimary</item>        
                <item name="colorPrimaryDark">@color/colorPrimaryDark</item>        
                <item name="colorAccent">@color/colorAccent</item>    
        </style>
</resources>`;

export const step6 = `<?xml version="1.0" encoding="utf-8"?>
<shape xmlns:android="http://schemas.android.com/apk/res/android"       
        android:shape="rectangle">    
        <solid android:color="#FFFFFF"/>    
        <corners android:radius="2dp"/>
</shape>`;

export const step7 = `<?xml version="1.0" encoding="utf-8"?>
<android.support.design.widget.CoordinatorLayout    
        mlns:android="http://schemas.android.com/apk/res/android"    
        xmlns:app="http://schemas.android.com/apk/res-auto"    
        android:layout_width="match_parent"    
        android:layout_height="match_parent">    

        <android.support.design.widget.AppBarLayout        
                android:layout_width="match_parent"        
                android:layout_height="wrap_content"        
                android:theme="@style/ThemeOverlay.AppCompat.Dark.ActionBar">        

                <android.support.v7.widget.Toolbar            
                        android:id="@+id/toolbar"            
                        android:layout_width="match_parent"            
                        android:layout_height="?attr/actionBarSize"            
                        android:background="?attr/colorPrimary"            
                        android:contentInsetLeft="0dp"            
                        android:contentInsetStart="0dp"            
                        android:weightSum="1"            
                        app:contentInsetLeft="0dp"            
                        app:contentInsetStart="0dp"            
                        app:layout_scrollFlags="scroll|enterAlways">            

                        <LinearLayout                
                                android:id="@+id/linearLayout_search"                
                                android:layout_width="match_parent"                
                                android:layout_height="match_parent"                
                                android:layout_marginBottom="3dp"                
                                android:layout_marginLeft="8dp"                
                                android:layout_marginRight="8dp"                
                                android:layout_marginTop="6dp"                
                                android:background="@drawable/button_rounded"                
                                android:elevation="3dp"                
                                android:gravity="center_vertical"                
                                android:orientation="horizontal"                
                                android:paddingLeft="10dp"                
                                android:paddingRight="10dp">                

                                <ImageView                    
                                        android:layout_width="25dp"                    
                                        android:layout_height="25dp"                    
                                        android:src="@drawable/icon_search"/>                

                                <TextView                    
                                        android:layout_width="wrap_content"                    
                                        android:layout_height="wrap_content"                    
                                        android:layout_marginLeft="16dp"                    
                                        android:text="Search for people and groups"                    
                                        android:textColor="#808080"                    
                                        android:textSize="14sp"/>      
                                        
                        </LinearLayout>        

                </android.support.v7.widget.Toolbar>        

                <android.support.design.widget.TabLayout            
                        android:id="@+id/tabs"            
                        android:layout_width="match_parent"            
                        android:layout_height="wrap_content"            
                        app:tabGravity="fill"            
                        app:tabIndicatorColor="@android:color/white"            
                        app:tabIndicatorHeight="2dp"            
                        app:tabMode="fixed"/>    

        </android.support.design.widget.AppBarLayout>    

        <android.support.v4.view.ViewPager        
                android:id="@+id/viewpager"        
                android:layout_width="match_parent"        
                android:layout_height="match_parent"        
                app:layout_behavior="@string/appbar_scrolling_view_behavior"/>

</android.support.design.widget.CoordinatorLayout>
`;

export const step8 = `public class MainActivity extends AppCompatActivity {    
        private Toolbar toolbar;    
        private TabLayout tabLayout;    
        private ViewPager viewPager;    
        private int[] tabIcons = {            
                R.drawable.icon_time,            
                R.drawable.icon_call,            
                R.drawable.icon_people,            
                R.drawable.icon_list,            
                R.drawable.icon_profile    
        };    
        
        @Override    
        protected void onCreate(Bundle savedInstanceState) {        
                super.onCreate(savedInstanceState);        
                setContentView(R.layout.activity_main);        
                toolbar = (Toolbar) findViewById(R.id.toolbar);        
                toolbar.setPadding(0, 0, 0, 0);        
                setSupportActionBar(toolbar);        
                
                LinearLayout search = (LinearLayout) findViewById(R.id.linearLayout_search);        
                search.setOnClickListener(new OnClickListener() {            
                        @Override            
                        public void onClick(View view) {                
                                Log.d("debug", "onClick: search");            
                        }        
                });        
                
                getSupportActionBar().setHomeButtonEnabled(false); // disable the button        
                getSupportActionBar().setDisplayHomeAsUpEnabled(false); // remove the left caret        
                getSupportActionBar().setDisplayShowHomeEnabled(false); // remove the icon        
                viewPager = (ViewPager) findViewById(R.id.viewpager);        
                setupViewPager(viewPager);        
                tabLayout = (TabLayout) findViewById(R.id.tabs);        
                tabLayout.setupWithViewPager(viewPager);        
                setupTabIcons();    
        }    
        
        private void setupTabIcons() {        
                tabLayout.getTabAt(0).setIcon(tabIcons[0]);        
                tabLayout.getTabAt(1).setIcon(tabIcons[1]);        
                tabLayout.getTabAt(2).setIcon(tabIcons[2]);        
                tabLayout.getTabAt(3).setIcon(tabIcons[3]);        
                tabLayout.getTabAt(4).setIcon(tabIcons[4]);    
        }    
        
        private void setupViewPager(ViewPager viewPager) {        
                ViewPagerAdapter adapter = new ViewPagerAdapter(getSupportFragmentManager());        
                adapter.addFragment(new Recent(), "");        
                adapter.addFragment(new Calls(), "");        
                adapter.addFragment(new Groups(), "");        
                adapter.addFragment(new FriendList(), "");        
                adapter.addFragment(new Profile(), "");        
                viewPager.setAdapter(adapter);    
        }    
        
        class ViewPagerAdapter extends FragmentPagerAdapter {        
                private final List<Fragment> mFragmentList = new ArrayList<>();        
                private final List<String> mFragmentTitleList = new ArrayList<>();        
                public ViewPagerAdapter(FragmentManager manager) {            
                        super(manager);        
                }        
                
                @Override        
                public Fragment getItem(int position) {            
                        return mFragmentList.get(position);        
                }        
                
                @Override        
                public int getCount() {            
                        return mFragmentList.size();        
                }        
                
                public void addFragment(Fragment fragment, String title) {            
                        mFragmentList.add(fragment);            
                        mFragmentTitleList.add(title);        
                }        
                
                @Override        
                public CharSequence 
                getPageTitle(int position) {            
                        return mFragmentTitleList.get(position);        
                }    
        }
}`;
