import { createSlice } from '@reduxjs/toolkit';

import banner1 from 'assets/jpg/blogs/banner-1.jpg';
import banner2 from 'assets/jpg/blogs/banner-2.jpg';
import banner3 from 'assets/jpg/blogs/banner-3.jpg';
import banner4 from 'assets/jpg/blogs/banner-4.jpg';
import banner5 from 'assets/jpg/blogs/banner-5.jpg';
import banner6 from 'assets/jpg/blogs/banner-6.jpg';
import banner7 from 'assets/jpg/blogs/banner-7.jpg';
import banner8 from 'assets/jpg/blogs/banner-8.jpg';
import banner9 from 'assets/jpg/blogs/banner-9.jpg';
// import banner10 from 'assets/jpg/blogs/banner-10.jpg';
// import banner11 from 'assets/jpg/blogs/banner-11.jpg';
// import banner12 from 'assets/jpg/blogs/banner-12.jpg';

const blogsSlice = createSlice({
  name: 'blogs',
  initialState: {
    blogs: [
      {
        banner: banner1,
        section: 'mobile',
        title: 'List View',
        longTitle: 'How to Use ListView in Android',
        tags: ['Android', 'Mobile Development'],
        author: 'Mark Edison Cua',
        duration: 3,
        date: 'November 27, 2017',
        path: '/blogs/mobile/android-list-view',
        relatedLinks: [
          '/blogs/mobile/android-custom-list-view',
          '/blogs/mobile/android-dialog',
        ],
      },
      {
        banner: banner2,
        section: 'mobile',
        title: 'Custom List View',
        longTitle: 'Creating a Customized ListView',
        tags: ['Android', 'Mobile Development'],
        author: 'Mark Edison Cua',
        duration: 5,
        date: 'December 6, 2017',
        path: '/blogs/mobile/android-custom-list-view',
        relatedLinks: [
          '/blogs/mobile/android-list-view',
          '/blogs/mobile/android-dialog',
        ],
      },
      {
        banner: banner3,
        section: 'mobile',
        title: 'Dialog Builders',
        longTitle: 'How to Use Dialog Builder',
        tags: ['Android', 'Mobile Development'],
        author: 'Mark Edison Cua',
        duration: 6,
        date: 'December 9, 2017',
        path: '/blogs/mobile/android-dialog',
        relatedLinks: [
          '/blogs/mobile/android-list-view',
          '/blogs/mobile/android-custom-list-view',
          '/blogs/mobile/android-flashlight-app',
        ],
      },
      {
        banner: banner4,
        section: 'mobile',
        title: 'Flashlight Application',
        longTitle: 'Creating a Simple Flashlight Application',
        tags: ['Android', 'Mobile Development'],
        author: 'Mark Edison Cua',
        duration: 12,
        date: 'January 4, 2018',
        path: '/blogs/mobile/android-flashlight-app',
        relatedLinks: [
          '/blogs/mobile/android-dialog',
          '/blogs/mobile/android-wallpaper-app',
        ],
      },
      {
        banner: banner5,
        section: 'mobile',
        title: 'Wallpaper Application',
        longTitle: 'Creating a Wallpaper Application',
        tags: ['Android', 'Mobile Development'],
        author: 'Mark Edison Cua',
        duration: 12,
        date: 'February 12, 2018',
        path: '/blogs/mobile/android-wallpaper-app',
        relatedLinks: [
          '/blogs/mobile/android-dialog',
          '/blogs/mobile/android-flashlight-app',
        ],
      },
      {
        banner: banner6,
        section: 'mobile',
        title: 'Customized Tabs',
        longTitle: 'Creating Facebook Messenger Tab Layout',
        tags: ['Android', 'Mobile Development'],
        author: 'Mark Edison Cua',
        duration: 20,
        date: 'February 17, 2018',
        path: '/blogs/mobile/android-custom-tab-layout',
        relatedLinks: [
          '/blogs/mobile/android-flashlight-app',
          '/blogs/mobile/android-wallpaper-app',
        ],
      },
      {
        banner: banner7,
        section: 'mobile',
        title: 'Android SQLite Database',
        longTitle: 'Android SQLite Database Tutorial with Material Design',
        tags: ['Android', 'Mobile Development'],
        author: 'Mark Edison Cua',
        duration: 30,
        date: 'February 24, 2018',
        path: '/blogs/mobile/android-sqlite',
        relatedLinks: [
          '/blogs/mobile/android-google-auth',
          '/blogs/mobile/android-complex-layout',
        ],
      },
      {
        banner: banner8,
        section: 'mobile',
        title: 'Google Authentication',
        longTitle: 'Implementing Google Sign In Using Android',
        tags: ['Android', 'Mobile Development', 'Google API'],
        author: 'Mark Edison Cua',
        duration: 23,
        date: 'March 2, 2018',
        path: '/blogs/mobile/android-google-auth',
        relatedLinks: [
          '/blogs/mobile/android-sqlite',
          '/blogs/mobile/android-complex-layout',
        ],
      },
      {
        banner: banner9,
        section: 'mobile',
        title: 'Android Complex Layout',
        longTitle: 'Instagram Like Feed Using CardView, RecyclerView and Glide',
        tags: ['Android', 'Mobile Development', 'UI/UX'],
        author: 'Mark Edison Cua',
        duration: 30,
        date: 'March 9, 2018',
        path: '/blogs/mobile/android-complex-layout',
        relatedLinks: [
          '/blogs/mobile/android-list-view',
          '/blogs/mobile/android-custom-list-view',
          '/blogs/mobile/android-dialog',
        ],
      },
      // {
      //   banner: banner10,
      //   section: 'mobile',
      //   title: 'Registration App - Part 1',
      //   tags: ['Android', 'Mobile Development', 'Database'],
      //   author: 'Mark Edison Cua',
      //   duration: 42,
      //   date: 'April 14, 2018',
      //   path: '/blogs/mobile/android-registration-app-part-1',
      // },
      // {
      //   banner: banner11,
      //   section: 'mobile',
      //   title: 'Registration App - Part 2',
      //   tags: ['Android', 'Mobile Development', 'Database'],
      //   author: 'Mark Edison Cua',
      //   duration: 48,
      //   date: 'April 21, 2018',
      //   path: '/blogs/mobile/android-registration-app-part-2',
      // },
      // {
      //   banner: banner12,
      //   section: 'mobile',
      //   title: 'Registration App - Part 3',
      //   tags: ['Android', 'Mobile Development', 'Database'],
      //   author: 'Mark Edison Cua',
      //   duration: 57,
      //   date: 'April 28, 2018',
      //   path: '/blogs/mobile/android-registration-app-part-3',
      // },
    ],
    page: 1,
    filter: '',
  },
  reducers: {
    setPage: (state, { payload }) => {
      state.page = payload;
    },
    setFilter: (state, { payload }) => {
      state.filter = payload;
    },
  },
  extraReducers: {},
});

export const { setPage, setFilter } = blogsSlice.actions;

export default blogsSlice.reducer;
