import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';
import { selectBlogs } from 'selectors/blogs';
import { getBlogDetails } from './helper';

import BlogContentItem from './BlogContentItem';

const BlogSuggestions = ({ relatedLinks }) => {
  const blogs = useSelector(selectBlogs);
  return (
    <React.Fragment>
      <Typography variant="h4">Related Topics</Typography>
      <Grid container>
        {relatedLinks.map((path) => {
          const details = getBlogDetails(path, blogs);
          return (
            <BlogContentItem
              key={details.path}
              thumbnail={details.banner}
              title={details.title}
              tags={details.tags}
              author={details.author}
              duration={details.duration}
              date={details.date}
              path={details.path}
            />
          );
        })}
      </Grid>
    </React.Fragment>
  );
};

BlogSuggestions.propTypes = {
  relatedLinks: PropTypes.array.isRequired,
};

export default BlogSuggestions;
