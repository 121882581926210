export const step1 = `<uses-permission android:name="android.permission.CAMERA"/>
<uses-feature android:name="android.hardware.Camera"/>`;

export const step2 = `<style name="AppTheme" parent="Theme.AppCompat.Light.NoActionBar">
        ...
</style>`;

export const step3 = `<activity   
    android:name=".MainActivity"    
    android:screenOrientation="portrait">
        ...
</activity>`;

export const step4 = `<?xml version="1.0" encoding="utf-8"?>
<RelativeLayout    
        xmlns:android="http://schemas.android.com/apk/res/android"    
        xmlns:tools="http://schemas.android.com/tools"    
        android:id="@+id/activity_main"    
        android:layout_width="match_parent"    
        android:layout_height="match_parent"    
        android:background="#222222"    
        tools:context="info.androidprime.flashlightapp.MainActivity">   

                <ImageView        
                android:id="@+id/imgViewButton"        
                android:layout_width="75dp"        
                android:layout_height="75dp"        
                android:layout_centerHorizontal="true"        
                android:layout_centerVertical="true"        
                android:src="@drawable/button"/>    

                <TextView        
                android:id="@+id/flashStatus"        
                android:layout_width="wrap_content"        
                android:layout_height="wrap_content"        
                android:layout_below="@+id/imgViewButton"        
                android:layout_centerHorizontal="true"        
                android:layout_marginTop="26dp"        
                android:text="Status: OFF"        
                android:textColor="#FFFFFF"        
                android:textSize="18sp"        
                android:textStyle="bold"/>

</RelativeLayout>`;

export const step5 = `private Camera camera;
private Parameters param;
private boolean hasFlash, isFlashOn;`;

export const step6 = `private void getCamera() {    
        if (camera == null) {        
                try {            
                        camera = Camera.open();            
                        param = camera.getParameters();        
                } catch (Exception e) {            
                        //try to do something here        
                }    
        }
}`;

export const step7 = `private void turnOnFlash() {    
        if (!isFlashOn) {        
                if (camera == null || param == null) {            
                        return;        
                }        
                param = camera.getParameters();        
                param.setFlashMode(Parameters.FLASH_MODE_TORCH);        
                camera.setParameters(param);        
                camera.startPreview();        
                isFlashOn = true;    
        }
}

private void turnOffFlash() {    
        if (isFlashOn) {        
                if (camera == null || param == null) {            
                        return;        
                }        
                param = camera.getParameters();        
                param.setFlashMode(Parameters.FLASH_MODE_OFF);        
                camera.setParameters(param);        
                camera.stopPreview();        
                isFlashOn = false;    
        }
}`;

export const step8 = `@Overrideprotected void onPause() {    
        super.onPause();    
        turnOffFlash();
}

@Overrideprotected void onStart() {    
        super.onStart();    
        getCamera();
}

@Overrideprotected void onStop() {    
        super.onStop();    
        if (camera != null) {        
                camera.release();        
                camera = null;    
        }
}
`;

export const step9 = `
//let's check if the device has camera flash
hasFlash = getPackageManager().hasSystemFeature(PackageManager.FEATURE_CAMERA_FLASH);
if (!hasFlash) {    
        //show an alert dialog if the device doesn't support camera flash    
        final AlertDialog.Builder alertDialogBuilder = new AlertDialog.Builder(MainActivity.this);    
        alertDialogBuilder.setCancelable(false);    
        alertDialogBuilder.setTitle("Incompatible Device");    
        alertDialogBuilder.setMessage("Sorry, your device doesn't support camera flash. " +            
        "Please use other device with this feature");    
        alertDialogBuilder.setPositiveButton("Got it", new DialogInterface.OnClickListener() {       
                 @Override        
                 public void onClick(DialogInterface dialogInterface, int i) {            
                         finish();//close the app        
                        }    
                });    
                
        final AlertDialog alertDialog = alertDialogBuilder.create();    
        alertDialog.show();    
        return;
}

getCamera();

final TextView flashStatus = (TextView) findViewById(R.id.flashStatus);
ImageView flashSwitch = (ImageView) findViewById(R.id.imgViewButton);
flashSwitch.setOnClickListener(new OnClickListener() {    
        @Override    
        public void onClick(View view) {        
                if (!isFlashOn) {            
                        turnOnFlash();            
                        flashStatus.setText("Status: ON");        
                } else {            
                        turnOffFlash();            
                        flashStatus.setText("Status: OFF");        
                }    
        }
});`;
