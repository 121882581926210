import React from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  Fade,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import webCoding from 'assets/png/languages-web-coding.png';
import ButtonArrow from 'components/Arrow';
import TypingLanguage from './TypingLanguage';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '15vh',
    marginBottom: '15vh',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
      marginBottom: '5em',
    },
  },
  image: {
    height: '30em',
    width: 'auto',
    [theme.breakpoints.only('md')]: {
      height: '24em',
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '3em',
      marginBottom: '1.5em',
      height: '20em',
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: '2.5em',
      marginBottom: '1em',
      height: '16em',
    },
  },
  learnButton: {
    ...theme.typography.learnButton,
  },
  typingContainer: {
    paddingLeft: '2em',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
    },
  },
  captionText: {
    width: '70%',
    fontSize: '1.5rem',
    fontWeight: 300,
    [theme.breakpoints.only('md')]: {
      fontSize: '1.3rem',
      textAlign: 'center',
      width: '100%',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.15rem',
      textAlign: 'center',
      width: '100%',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.9rem',
      textAlign: 'center',
      width: '100%',
    },
  },
}));

const ProgrammingLanguageBlock = () => {
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));

  const clickHandler = () => {
    history.push('/profile');
  };

  return (
    <Grid container direction="row" className={styles.root}>
      <Grid
        item
        container
        alignItems="center"
        justify="center"
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
      >
        <Fade in timeout={1000}>
          <Grid item>
            <img
              src={webCoding}
              alt="Programming Language Logo"
              className={styles.image}
            />
          </Grid>
        </Fade>
      </Grid>
      <Grid
        item
        container
        alignItems="center"
        justify="flex-start"
        xs={12}
        sm={12}
        md={6}
        lg={6}
        xl={6}
        className={styles.typingContainer}
      >
        <Grid item container direction="column" spacing={4}>
          <Grid item>
            <TypingLanguage />
          </Grid>
          <Grid item>
            <Typography className={styles.captionText}>
              8 years of solid experience in software development. Published
              several apps and games in the Google Playstore and App Store.
            </Typography>
          </Grid>
          <Grid item container justify={mdDown ? 'center' : undefined}>
            <Button
              disableElevation
              disableRipple
              variant="outlined"
              className={styles.learnButton}
              onClick={clickHandler}
            >
              <span style={{ marginRight: 10 }}>Learn more about me</span>
              <ButtonArrow
                width={25}
                height={25}
                fill={theme.palette.accent.main}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ProgrammingLanguageBlock;
