import React from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { Grid, Fade, makeStyles, Typography, Button } from '@material-ui/core';

import headerLogo from 'assets/svg/developer-mode.svg';
import headerLogoWhite from 'assets/svg/developer-mode-light.svg';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 'auto',
    height: '5em',
    minWidth: '5em',
    marginRight: '0.5em',
    [theme.breakpoints.only('md')]: {
      height: '4.5em',
      minWidth: '4.5em',
    },
    [theme.breakpoints.only('sm')]: {
      height: '4em',
      minWidth: '4em',
      marginRight: '0.25em',
    },
    [theme.breakpoints.only('xs')]: {
      height: '3.5em',
      minWidth: '3.5em',
      marginRight: 0,
    },
  },
  logoText: {
    fontSize: '1rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
    [theme.breakpoints.only('md')]: {
      fontSize: '0.9rem',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.82rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.75rem',
    },
  },
  logoTextLight: {
    color: 'white',
  },
  logoTextDark: {
    color: theme.palette.text.primary,
  },
  button: {
    paddingLeft: 0,
    paddingRight: 0,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));

const AppHeaderLogo = ({ light }) => {
  const styles = useStyles();
  const history = useHistory();

  const logoClickHandler = () => {
    history.push('/home');
  };

  return (
    <React.Fragment>
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Fade in timeout={light ? 2000 : 1000}>
          <Grid item>
            <Button
              className={styles.button}
              disableFocusRipple
              disableRipple
              onClick={logoClickHandler}
            >
              <img
                src={light ? headerLogoWhite : headerLogo}
                alt="header logo"
                className={styles.logo}
              />
            </Button>
          </Grid>
        </Fade>
        <Fade in timeout={light ? 2000 : 1000}>
          <Grid item>
            <Grid item container direction="column">
              <Typography
                className={[
                  styles.logoText,
                  light ? styles.logoTextLight : styles.logoTextDark,
                ].join(' ')}
              >
                The
              </Typography>
              <Typography
                className={[
                  styles.logoText,
                  light ? styles.logoTextLight : styles.logoTextDark,
                ].join(' ')}
              >
                Humble
              </Typography>
              <Typography
                className={[
                  styles.logoText,
                  light ? styles.logoTextLight : styles.logoTextDark,
                ].join(' ')}
              >
                Developer
              </Typography>
            </Grid>
          </Grid>
        </Fade>
      </Grid>
    </React.Fragment>
  );
};

AppHeaderLogo.defaultProps = {
  light: false,
};

AppHeaderLogo.propTypes = {
  light: PropTypes.bool,
};

export default AppHeaderLogo;
