import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import CareerTimeline from './CareerTimeline';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '6em',
    paddingLeft: '14em',
    paddingRight: '14em',
    [theme.breakpoints.only('md')]: {
      padding: '4em',
    },
    [theme.breakpoints.down('sm')]: {
      paddingTop: '4em',
      paddingBottom: '4em',
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  title: {
    textAlign: 'center',
  },
  timeline: {
    marginTop: '4em',
  },
}));

const CareerPath = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Grid container direction="column" className={styles.root}>
        <Grid item>
          <Typography variant="h4" className={styles.title}>
            Career Highlights
          </Typography>
        </Grid>
        <Grid item className={styles.timeline}>
          <CareerTimeline />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default CareerPath;
