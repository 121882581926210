export const step1 = `<?xml version="1.0" encoding="utf-8"?>
<RelativeLayout 
        xmlns:android="http://schemas.android.com/apk/res/android" 
        xmlns:tools="http://schemas.android.com/tools" 
        android:id="@+id/activity_main" 
        android:layout_width="match_parent" 
        android:layout_height="match_parent" 
        tools:context="androidprime.info.simplelistview.MainActivity">

                <ListView android:id="@+id/listView" 
                android:layout_width="match_parent" 
                android:layout_height="match_parent" 
                android:layout_alignParentTop="true" 
                android:layout_alignParentLeft="true" 
                android:layout_alignParentStart="true"/>

</RelativeLayout>`;

export const step2 = `public class MainActivity extends AppCompatActivity {    
        @Override   
        protected void onCreate(Bundle savedInstanceState) 
        {        
            super.onCreate(savedInstanceState);        
            setContentView(R.layout.activity_main);        
            final String[] ANDROID_OS = new String[] {"Cupcake", "Donut", "Eclaire", "Froyo",                
            "GingerBread", "HoneyComb", "Ice Cream Sandwich", "Jelly Bean", "KitKat",                
            "Lollipop", "MarshMallow", "Nougat"};    
        }
}`;

export const step3 = `public class MainActivity extends AppCompatActivity {    
        @Override    
        protected void onCreate(Bundle savedInstanceState) {        
            super.onCreate(savedInstanceState);        
            setContentView(R.layout.activity_main);        
            final String[] ANDROID_OS = new String[] {"Cupcake", "Donut", "Eclaire", "Froyo",                
            "GingerBread", "HoneyComb", "Ice Cream Sandwich", "Jelly Bean", "KitKat",                
            "Lollipop", "MarshMallow", "Nougat"};        
            ArrayAdapter<String> arrayAdapter = new ArrayAdapter<>(this, android.R.layout.simple_list_item_1, ANDROID_OS);        
            ListView listView = (ListView) findViewById(R.id.listView);        
            listView.setAdapter(arrayAdapter);    
        }
}`;
