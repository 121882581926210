import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from '@material-ui/core';

import SocialLinks from './SocialLinks';
import MenuLinks from './MenuLinks';
import ContactLinks from './ContactLinks';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'auto',
    minHeight: '20vh',
    width: '100%',
    background: '#313D3F',
    padding: '2em',
  },
  container: {
    width: '70%',
    [theme.breakpoints.down('lg')]: {
      width: '90%',
    },
  },
  rights: {
    color: 'white',
    fontWeight: 300,
    marginTop: '1em',
  },
  divider: {
    height: '100%',
    width: '0.05em',
    background: 'transparent',
    opacity: 0.7,
    marginLeft: '8em',
  },
  bottomDivider: {
    marginTop: '0.5em',
  },
}));

const AppFooter = ({ showLogo }) => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <Grid container justify="center" className={styles.root}>
        <Grid
          container
          direction="column"
          spacing={1}
          className={styles.container}
        >
          <Grid item container justify="flex-start">
            <SocialLinks showLogo={showLogo} />
            <Hidden mdDown>
              <Grid item>
                <div className={styles.divider} />
              </Grid>
              <MenuLinks />
            </Hidden>
            <Hidden smDown>
              <Grid item>
                <div className={styles.divider} />
              </Grid>
              <ContactLinks />
            </Hidden>
          </Grid>
          <Grid item className={styles.bottomDivider}>
            <Divider />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" className={styles.rights}>
              The Humble Developer
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

AppFooter.defaultProps = {
  showLogo: false,
};

AppFooter.propTypes = {
  showLogo: PropTypes.bool,
};

export default AppFooter;
