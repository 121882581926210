import { createSelector } from 'reselect';

const selectBlogsDomain = (state) => state.blogs;

export const selectBlogs = createSelector(
  selectBlogsDomain,
  (state) => state.blogs
);

export const selectFilter = createSelector(
  selectBlogsDomain,
  (state) => state.filter
);

export const selectPage = createSelector(
  selectBlogsDomain,
  (state) => state.page
);
