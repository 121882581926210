import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';
import {
  blogStyles,
  ClickableLink,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output from 'assets/png/blogs/T2-output.png';
import { step1, step2, step3, step4, step5, step6 } from './codebase';

const BlogsAndroidCustomListView = () => {
  const styles = blogStyles();
  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />
      <Typography paragraph>
        In our previous tutorial, we learned how to use a simple ListView in our
        Android project. But the problem is, what if we want to an add image or
        multiple TextViews inside a single item to be inserted to a ListView? In
        this tutorial I am going to teach you how to create your own customized
        ListView.
      </Typography>
      <Typography paragraph>
        In this tutorial we are going to make an application that will display
        list of companies such as Google, Facebook, Samsung and etc.. First we
        need to know how the items inside the ListView will look like.
        Let&apos;s make it as simple as possible. I want to show their logo,
        their company name and a short description about them.
      </Typography>
      <Typography paragraph>
        Before we start the tutorial, you need to download all the company logo
        we are going to use for this tutorial. You can download it here:
        Download Link. Create a new blank project in Android Studio then copy
        and paste all the images in your project&apos;s drawable folder.
      </Typography>
      <Typography paragraph>
        Now that we already know how the items will look like and we have all
        the assets needed, we can start by creating a new XML layout file under{' '}
        <b>res/layout</b> folder and name it <b>custom_item.xml</b>. Delete all
        the codes inside the <b>custom_item.xml</b> and copy the codes provided
        below.
      </Typography>
      <CodeSnippet codes={step1} />
      <Typography paragraph>
        Of course we need a listview in our main activity. Open
        <b>activity_main.xml</b> and include a listview just like what we did in
        our last tutorial or you can copy the codes provided below in your
        <b>activity_main.xml</b>.
      </Typography>
      <CodeSnippet codes={step2} />
      <Typography paragraph>
        Now create three more java classes just like in the image shown below.
      </Typography>
      <Typography paragraph>
        <b>Constants.java</b> - We&apos;ll place all constant information here
        such as company name, company logo and short descriptions.
      </Typography>
      <Typography paragraph>
        <b>CustomAdapter.java</b> - Since we are going to create a custom
        listview, we also need to create a custom adapter that will bind the
        data to our listview. In our previous tutorial, we used the default
        array adapter and simply bind the string array but that won&apos;t work
        here because our listview items have an image and two textviews.
      </Typography>
      <Typography paragraph>
        <b>CustomListItem.java</b> - This will be a model class for listview
        items.
      </Typography>
      <Typography paragraph>
        Now that I&apos;ve explained the roles of each classes, open{' '}
        <b>Constants.java</b> then copy and paste the codes below.
      </Typography>
      <CodeSnippet codes={step3} />
      <Typography paragraph>
        Next, open <b>CustomListItem.java</b> then copy and paste the codes
        below.
      </Typography>
      <CodeSnippet codes={step4} />
      <Typography paragraph>
        finally, open <b>CustomAdapter.java</b> then copy and paste the codes
        below.
      </Typography>
      <CodeSnippet codes={step5} />
      <Typography paragraph>
        You can read more about BaseAdapter here:{' '}
        <ClickableLink
          href="https://developer.android.com/reference/android/widget/BaseAdapter.html"
          label="Base Adapter"
        />
      </Typography>
      <Typography paragraph>
        Now open <b>MainActivity.java</b> then copy and paste the codes below.
      </Typography>
      <CodeSnippet codes={step6} />
      <Typography paragraph>
        Now you can run and test you app if it is working. The final output of
        this tutorial should be like this.
      </Typography>
      <ScreenShot source={output} />
      <Divider className={styles.divider} />
    </BlogContentLayout>
  );
};

export default BlogsAndroidCustomListView;
