import React from 'react';
import {
  Button,
  Divider,
  Fade,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import ButtonArrow from 'components/Arrow';
import { useTheme } from '@material-ui/styles';
import unityLogo from 'assets/jpg/unity-logo.jpg';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '4em',
    marginBottom: '4em',
  },
  title: {
    fontWeight: 300,
    [theme.breakpoints.down('md')]: {
      marginTop: '2em',
    },
  },
  caption: {
    maxWidth: '20em',
    [theme.breakpoints.between('md', 'lg')]: {
      maxWidth: '20em',
    },
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '20em',
      fontSize: '1.4rem',
    },
    [theme.breakpoints.only('xs')]: {
      maxWidth: '20em',
      fontSize: '1.2rem',
    },
  },
  learnButton: {
    ...theme.typography.learnButton,
    marginTop: '2em',
  },
  unityLogo: {
    height: '15em',
    width: 'auto',
    marginRight: '2em',
    [theme.breakpoints.only('md')]: {
      height: '12em',
    },
    [theme.breakpoints.down('sm')]: {
      height: '10em',
    },
    [theme.breakpoints.down('md')]: {
      marginRight: 0,
    },
  },
}));

const GameTitle = () => {
  const styles = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const onClickHandler = () => {
    window.open('https://unity.com/solutions/2d', '_blank');
  };
  return (
    <React.Fragment>
      <Grid
        container
        direction={mdDown ? 'column' : 'row'}
        justify="center"
        alignItems="center"
        className={styles.root}
      >
        <Grid item>
          <Fade in timeout={1000}>
            <img
              src={unityLogo}
              alt="Unity Logo"
              className={styles.unityLogo}
            />
          </Fade>
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            alignItems={mdDown ? 'center' : undefined}
            spacing={2}
          >
            <Grid item>
              <Typography variant="h6" className={styles.title}>
                Games
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h4" className={styles.caption}>
                These interactive games are developed using Unity 2D Game
                Engine.
              </Typography>
            </Grid>
            <Grid item>
              <Button
                disableElevation
                disableRipple
                variant="outlined"
                className={styles.learnButton}
                onClick={onClickHandler}
              >
                <span style={{ marginRight: 10 }}>
                  Read more about Unity 2D
                </span>
                <ButtonArrow
                  width={25}
                  height={25}
                  fill={theme.palette.accent.main}
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </React.Fragment>
  );
};

export default GameTitle;
