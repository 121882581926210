import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  useHistory,
} from 'react-router-dom';
import { setCurrentPage } from 'store/resourcesSlice';
import { makeStyles } from '@material-ui/core';
import RouteListener from 'components/RouteListener';
import VizSensor from 'react-visibility-sensor';

// pages
import Home from 'pages/Home';
import Profile from 'pages/Profile';
import Games from 'pages/Games';
import PageNotFound from 'pages/PageNotFound';
import Blogs from 'pages/Blogs';
import BlogsAndroidComplexLayout from 'pages/Blogs/Mobile/BlogsAndroidComplexLayout';
import BlogsAndroidCustomListView from 'pages/Blogs/Mobile/BlogsAndroidCustomListView';
import BlogsAndroidCustomTabs from 'pages/Blogs/Mobile/BlogsAndroidCustomTabs';
import BlogsAndroidDialog from 'pages/Blogs/Mobile/BlogsAndroidDialog';
import BlogsAndroidFlashLightApp from 'pages/Blogs/Mobile/BlogsAndroidFlashLightApp';
import BlogsAndroidGoogleSignIn from 'pages/Blogs/Mobile/BlogsAndroidGoogleSignIn';
import BlogsAndroidListView from 'pages/Blogs/Mobile/BlogsAndroidListView';
import BlogsAndroidRegistrationApp1 from 'pages/Blogs/Mobile/BlogsAndroidRegistrationApp1';
import BlogsAndroidRegistrationApp2 from 'pages/Blogs/Mobile/BlogsAndroidRegistrationApp2';
import BlogsAndroidRegistrationApp3 from 'pages/Blogs/Mobile/BlogsAndroidRegistrationApp3';
import BlogsAndroidSQLite from 'pages/Blogs/Mobile/BlogsAndroidSQLite';
import BlogsAndroidWallpaperApp from 'pages/Blogs/Mobile/BlogsAndroidWallpaperApp';

import './index.css';
import AppHeader from 'components/AppHeader';
import AppHeaderSecondary from 'components/AppHeader/components/AppHeaderSecondary';

const useStyles = makeStyles(() => ({
  divider: {
    width: '100%',
    height: '0.1em',
    background: 'transparent',
  },
}));

const App = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [showToolbar, setShowToolbar] = useState(false);
  useEffect(() => {
    dispatch(setCurrentPage(history.location.pathname));
  }, [dispatch, history]);

  return (
    <React.Fragment>
      <BrowserRouter>
        <AppHeader />
        {showToolbar ? <AppHeaderSecondary show={showToolbar} /> : null}
        <VizSensor
          onChange={(isVisible) => {
            setShowToolbar(!isVisible);
          }}
        >
          <div className={styles.divider} />
        </VizSensor>
        <RouteListener>
          <Switch>
            <Route path="/home" component={Home} exact />
            <Route path="/profile" component={Profile} exact />
            <Route path="/games" component={Games} exact />
            <Route path="/blogs" component={Blogs} exact />
            <Route
              path="/blogs/mobile/android-complex-layout"
              component={BlogsAndroidComplexLayout}
              exact
            />
            <Route
              path="/blogs/mobile/android-custom-list-view"
              component={BlogsAndroidCustomListView}
              exact
            />
            <Route
              path="/blogs/mobile/android-custom-tab-layout"
              component={BlogsAndroidCustomTabs}
              exact
            />
            <Route
              path="/blogs/mobile/android-dialog"
              component={BlogsAndroidDialog}
              exact
            />
            <Route
              path="/blogs/mobile/android-flashlight-app"
              component={BlogsAndroidFlashLightApp}
              exact
            />
            <Route
              path="/blogs/mobile/android-google-auth"
              component={BlogsAndroidGoogleSignIn}
              exact
            />
            <Route
              path="/blogs/mobile/android-list-view"
              component={BlogsAndroidListView}
              exact
            />
            <Route
              path="/blogs/mobile/android-registration-app-part-1"
              component={BlogsAndroidRegistrationApp1}
              exact
            />
            <Route
              path="/blogs/mobile/android-registration-app-part-2"
              component={BlogsAndroidRegistrationApp2}
              exact
            />
            <Route
              path="/blogs/mobile/android-registration-app-part-3"
              component={BlogsAndroidRegistrationApp3}
              exact
            />
            <Route
              path="/blogs/mobile/android-sqlite"
              component={BlogsAndroidSQLite}
              exact
            />
            <Route
              path="/blogs/mobile/android-wallpaper-app"
              component={BlogsAndroidWallpaperApp}
              exact
            />
            <Route path="/" component={() => <Redirect to="/home" />} exact />
            <Route component={PageNotFound} />
          </Switch>
        </RouteListener>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
