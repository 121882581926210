import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'service/auth.service';
import { setPending } from './resourcesSlice';

export const login = createAsyncThunk(
  'auth/login',
  async (_, { rejectWithValue }) => {
    try {
      return Auth.instance.signIn();
    } catch {
      return rejectWithValue(false);
    }
  }
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (_, { rejectWithValue, dispatch }) => {
    dispatch(setPending(true));
    try {
      return Auth.instance.signOut();
    } catch (err) {
      return rejectWithValue(false);
    }
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    loggedIn: false,
  },
  reducers: {
    setLoggedIn: (state, { payload }) => {
      state.loggedIn = payload;
    },
  },
  extraReducers: {},
});

export const { setLoggedIn } = authSlice.actions;

export default authSlice.reducer;
