import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import client, { connectClient, authClient } from 'service/client';
import { endpoints } from 'common/constants';
import resourcesSlice from './resourcesSlice';
import authSlice from './authSlice';
import blogSlice from './blogSlice';

export const history = createBrowserHistory();

export default configureStore({
  reducer: {
    router: connectRouter(history),
    resources: resourcesSlice,
    auth: authSlice,
    blogs: blogSlice,
  },
  middleware: [
    routerMiddleware(history),
    thunk.withExtraArgument({
      client,
      connectClient,
      authClient,
      endpoints,
    }),
  ],
});
