import React from 'react';
import { AppBar, Toolbar, Grid, makeStyles, Hidden } from '@material-ui/core';

import AppHeaderTabs from './components/AppHeaderTabs';
import AppHeaderMenu from './components/AppHeaderMenu';
import AppHeaderLogo from './components/AppHeaderLogo';

const useStyles = makeStyles((theme) => ({
  appBar: {
    background: '#F0F0F0',
    boxShadow: 'none',
    paddingLeft: '4em',
    paddingRight: '4em',
    paddingTop: '2em',
    paddingBottom: '2em',
    [theme.breakpoints.only('md')]: {
      padding: '2em',
    },
    [theme.breakpoints.only('sm')]: {
      padding: '2em',
    },
    [theme.breakpoints.only('xs')]: {
      padding: '1.5em',
    },
  },
  toolbar: {},
}));

const AppHeader = () => {
  const styles = useStyles();
  return (
    <AppBar position="static" className={styles.appBar}>
      <Toolbar disableGutters className={styles.toolbar}>
        <AppHeaderLogo />
        <Grid item container justify="flex-end">
          <Hidden smDown>
            <AppHeaderTabs />
          </Hidden>
          <Hidden mdUp>
            <AppHeaderMenu />
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
