import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  AppBar,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Slide,
  Toolbar,
} from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import AppHeaderMenuDialog from './AppHeaderMenuDialog';
import AppHeaderLogo from './AppHeaderLogo';

const useStyles = makeStyles((theme) => ({
  appBar: {
    [theme.breakpoints.up('sm')]: {
      background: 'transparent',
      boxShadow: 'none',
    },
  },
  toolbar: {
    paddingTop: '2.5em',
    paddingRight: '2.5em',
    [theme.breakpoints.only('sm')]: {
      paddingTop: '2.1em',
      paddingBottom: '2.5em',
      paddingRight: '2.95em',
      paddingLeft: '2.95em',
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '1.5em',
      paddingBottom: '1.5em',
      paddingRight: '1.5em',
      paddingLeft: '1.5em',
    },
  },
  toolbarIcon: {
    fill: theme.palette.text.primary,
    [theme.breakpoints.only('xs')]: {
      fill: 'white',
    },
  },
  toolbarIconContainer: {
    [theme.breakpoints.up('sm')]: {
      background: '#ffffff33',
      borderRadius: '0.25em',
    },
  },
}));

const AppHeaderSecondary = ({ show }) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      {show ? (
        <AppBar position="fixed" className={styles.appBar}>
          <Slide in={show}>
            <Toolbar disableGutters className={styles.toolbar}>
              <Hidden smUp>
                <AppHeaderLogo light />
              </Hidden>
              <Grid item container justify="flex-end">
                <Grid item>
                  <div className={styles.toolbarIconContainer}>
                    <IconButton aria-label="drawer" onClick={handleClickOpen}>
                      <MenuIcon className={styles.toolbarIcon} />
                    </IconButton>
                  </div>
                </Grid>
              </Grid>
            </Toolbar>
          </Slide>
        </AppBar>
      ) : null}
      <AppHeaderMenuDialog open={open} handleClose={handleClose} />
    </React.Fragment>
  );
};

AppHeaderSecondary.propTypes = {
  show: PropTypes.bool.isRequired,
};
export default AppHeaderSecondary;
