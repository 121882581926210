import React from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';

import email from 'assets/svg/email.svg';
import phone from 'assets/svg/phone.svg';
import map from 'assets/svg/map.svg';

const useStyles = makeStyles(() => ({
  connect: {
    color: 'white',
    marginLeft: '1em',
    maxWidth: '18em',
    fontWeight: 300,
    fontSize: '0.8rem',
  },
  contact: {
    color: 'white',
  },
  contactLogo: {
    width: 'auto',
    height: '1.25em',
    marginRight: '0.5em',
    opacity: 0.85,
  },
  contactDetails: {
    color: 'white',
    fontWeight: 300,
    opacity: 0.75,
  },
  contactAddressContainer: {
    maxWidth: '20em',
  },
}));

const ContactLinks = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Grid item>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="subtitle1" className={styles.contact}>
              Contact
            </Typography>
          </Grid>
          <Grid item container alignItems="center">
            <img src={email} alt="Email Link" className={styles.contactLogo} />
            <Typography variant="subtitle1" className={styles.contactDetails}>
              mark.r.cua@gmail.com
            </Typography>
          </Grid>
          <Grid item container alignItems="center">
            <img src={phone} alt="Phone Link" className={styles.contactLogo} />
            <Typography variant="subtitle1" className={styles.contactDetails}>
              +63-918-639-4053
            </Typography>
          </Grid>
          <Grid item container direction="row" alignItems="flex-start">
            <Grid item>
              <img
                src={map}
                alt="Address Link"
                className={styles.contactLogo}
                style={{ marginTop: '0.25em' }}
              />
            </Grid>
            <Grid item className={styles.contactAddressContainer}>
              <Typography variant="subtitle1" className={styles.contactDetails}>
                Suntrust Villas Condominium, Coronado Street Brgy. Hulo,
                Mandaluyong City, 1550, Philippines
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ContactLinks;
