import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';

import {
  blogStyles,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output1 from 'assets/png/blogs/T5-output1.png';
import output2 from 'assets/png/blogs/T5-output2.png';
import { step1, step2, step3, step4, step5, step6 } from './codebase';

const BlogsAndroidWallpaperApp = () => {
  const styles = blogStyles();
  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />

      <Typography paragraph>
        In this tutorial I&apos;m going to teach you how to use Android Context
        Menu and GridView. We&apos;re going to creating a simple wallpaper
        application that will allow the users to choose and apply a wallpaper in
        our gallery.
      </Typography>

      <Typography paragraph>
        <b>What are Context Menus?</b>
      </Typography>

      <Typography paragraph>
        Context menus are associated with views and are triggered by performing
        a long click in a view for a certain period of time.
      </Typography>

      <Typography paragraph component="span">
        <b>How to use Context Menus?</b>
        <ul>
          1 Declare and initialize a view component. (Buttons, ImageView,
          TextView and etc..)
        </ul>
        <ul>
          2 Register the view for context menu by using
          registerForContextMenu(view)
        </ul>
        <ul>3 Override onCreateContextMenu() inside the activity</ul>
        <ul>4 Call the method add() of class ContextMenu</ul>
      </Typography>

      <Typography paragraph>
        <b>What are GridViews?</b>
      </Typography>
      <Typography paragraph>
        Gridview is a group of view that displays items in a 2-dimensional,
        scrollable grid. The grid items are inserted to the layout using an
        adapter.
      </Typography>

      <Typography paragraph>
        Now let&apos;s start creating our app by creating a new blank project in
        Android Studio. First we need to add a permission that will allow our
        app to change the device&apos;s current wallpaper. Open{' '}
        <b>AndroidManifest.xml</b> and add this permission.
      </Typography>
      <CodeSnippet codes={step1} />

      <Typography paragraph>
        Download the images that we will use in our galery here and then copy
        and paste them in your drawable folder. Create a new activity that will
        handle the full image preview. Then create a new class that we&apos;ll
        use as our adapter. After this, you should now have three classes in
        your java folder. Now open ImageAdapter.java and add the codes provided
        below. We need to extend BaseAdapter so we can override the methods used
        in an adapter.
      </Typography>
      <CodeSnippet codes={step2} />

      <Typography paragraph>
        This class will serve as the adapter of our gridview. The getView method
        will be called every time we&apos;re going to insert an item in our
        GridView. Now open <b>activity_image_review.xml</b> and add a full size
        ImageView. This activity will show the selected image in full screen.
      </Typography>
      <CodeSnippet codes={step3} />

      <Typography paragraph>
        Then open <b>ImagePreview.java</b> and add the codes provided below.
      </Typography>
      <CodeSnippet codes={step4} />

      <Typography paragraph>
        In this activity, we&apos;re just simply getting the resource id inside
        the intent pass by the previous activity. Then we will use that resource
        id to set the selected image in our ImageView. Now open {''}
        <b>activity_main.xml</b> and add a full size GridView.
      </Typography>
      <CodeSnippet codes={step5} />

      <Typography paragraph>
        The open <b>MainActivity.java</b> and add the codes provided below.
      </Typography>
      <CodeSnippet codes={step6} />

      <Typography paragraph>
        As I said earlier, there are steps in order to use the context menu.
        First we declare and initialize our GridView then we register it by
        using <b>registerForContextMenu(view)</b>. Next we override{' '}
        <b>onCreateContextMenu()</b> and add the items to be shown in our
        context menu. Lastly we need to override <b>onContextItemSelected()</b>{' '}
        in order to add a function that will be triggered when a user choose an
        item inside the context menu. Now you can run and test you app if it is
        working. The final output of this tutorial should be like this. You need
        to perform a long click on an item to show the context menu.
      </Typography>

      <ScreenShot source={output1} />
      <ScreenShot source={output2} />
      <Divider className={styles.divider} />
    </BlogContentLayout>
  );
};

export default BlogsAndroidWallpaperApp;
