import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import AppFooter from 'components/AppFooter';
import BlogsTitle from './components/BlogsTitle';
import BlogsTabs from './components/BlogsTabs';
import BlogContentMain from './components/BlogContent';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '8em',
    paddingRight: '8em',
    [theme.breakpoints.only('md')]: {
      paddingLeft: '6em',
      paddingRight: '6em',
    },
    [theme.breakpoints.only('sm')]: {
      paddingLeft: '4em',
      paddingRight: '4em',
    },
    [theme.breakpoints.only('xs')]: {
      paddingLeft: '1em',
      paddingRight: '1em',
    },
  },
}));

const Blogs = () => {
  const styles = useStyles();

  return (
    <React.Fragment>
      <Grid container direction="column" className={styles.root}>
        <BlogsTitle />
        <BlogsTabs />
        <BlogContentMain />
      </Grid>
      <AppFooter />
    </React.Fragment>
  );
};

export default Blogs;
