import React from 'react';
import { Grid } from '@material-ui/core';
import AppFooter from 'components/AppFooter';
import AboutMe from './components/AboutMe';
import MySkills from './components/MySkills';
import CareerPath from './components/CareerPath';
import Certifications from './components/Certifications';

const Profile = () => {
  return (
    <React.Fragment>
      <Grid container direction="column">
        <AboutMe />
        <Certifications />
        <MySkills />
        <CareerPath />
      </Grid>
      <AppFooter showLogo />
    </React.Fragment>
  );
};

export default Profile;
