import React, { useState } from 'react';
import { useTheme } from '@material-ui/styles';
import { IconButton } from '@material-ui/core';

import MenuIcon from '@material-ui/icons/Menu';
import AppHeaderMenuDialog from './AppHeaderMenuDialog';

const AppHeaderMenu = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleClickOpen} aria-label="open">
        <MenuIcon style={{ fill: theme.palette.text.primary }} />
      </IconButton>
      <AppHeaderMenuDialog open={open} handleClose={handleClose} />
    </React.Fragment>
  );
};

export default AppHeaderMenu;
