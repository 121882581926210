import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { selectBlogs } from 'selectors/blogs';
import PropTypes from 'prop-types';
import {
  AppBar,
  Breadcrumbs,
  Grid,
  Hidden,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import AppFooter from 'components/AppFooter';
import Link from '@material-ui/core/Link';
import BackIcon from '@material-ui/icons/ArrowBack';
import BlogSuggestions from './BlogSuggestions';
import { getBlogDetails } from './helper';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '2em',
    paddingBottom: '2em',
    paddingRight: '20vw',
    paddingLeft: '20vw',
    [theme.breakpoints.only('lg')]: {
      paddingRight: '16vw',
      paddingLeft: '16vw',
    },
    [theme.breakpoints.only('md')]: {
      paddingTop: '2em',
      paddingBottom: '2em',
      paddingRight: '6em',
      paddingLeft: '6em',
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: '1em',
      paddingBottom: '1em',
      paddingRight: '4em',
      paddingLeft: '4em',
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '1em',
      paddingBottom: '2em',
      paddingRight: '2em',
      paddingLeft: '2em',
    },
  },
  appBar: {
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbar: {
    marginTop: '2.5em',
    marginBottom: '2.5em',
    [theme.breakpoints.only('md')]: {
      marginTop: '2em',
      marginBottom: '2em',
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '1em',
      marginBottom: '1em',
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  backIcon: {
    fill: theme.palette.text.primary,
    marginRight: '1em',
  },
  backIconContainer: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  title: {
    marginBottom: '0.5em',
  },
  author: {},
  bullet: {
    marginRight: '0.5em',
    marginLeft: '0.5em',
  },
  duration: {
    fontWeight: 300,
  },
  divider: {
    margin: '1em',
  },
  date: {},
}));

const BlogContentLayout = ({ children }) => {
  const styles = useStyles();
  const history = useHistory();
  const blogs = useSelector(selectBlogs);
  const path = useHistory().location.pathname;
  const details = getBlogDetails(path, blogs);

  const handleClick = (event) => {
    event.preventDefault();
    history.push(event.target.pathname);
  };

  const backButtonHandler = () => {
    history.goBack();
  };

  return (
    <React.Fragment>
      <div className={styles.root}>
        <AppBar position="static" className={styles.appBar}>
          <Toolbar disableGutters className={styles.toolbar}>
            <Hidden xsDown>
              <IconButton
                aria-label="back"
                edge="start"
                className={styles.backIconContainer}
                disableRipple
                disableFocusRipple
                onClick={backButtonHandler}
              >
                <BackIcon className={styles.backIcon} />
              </IconButton>
            </Hidden>
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/blogs" onClick={handleClick}>
                blogs
              </Link>
              <Link
                color="inherit"
                href={`/blogs/${details.section}`}
                onClick={handleClick}
              >
                {details.section}
              </Link>
              <Hidden xsDown>
                <Typography color="textPrimary">{details.title}</Typography>
              </Hidden>
            </Breadcrumbs>
          </Toolbar>
        </AppBar>

        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h4" className={styles.title}>
                {details.longTitle}
              </Typography>
            </Grid>
            <Grid item container direction="row" alignItems="center">
              <Grid item>
                <Typography variant="subtitle1" className={styles.author}>
                  Author: {details.author}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" className={styles.bullet}>
                  •
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" className={styles.duration}>
                  {details.duration} mins read
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Typography variant="caption" className={styles.date}>
                published on {details.date}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {children}
        <BlogSuggestions relatedLinks={details.relatedLinks} />
      </div>

      <AppFooter showLogo />
    </React.Fragment>
  );
};

BlogContentLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BlogContentLayout;
