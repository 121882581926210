import React from 'react';
// import Typing from 'react-typing-animation';
import { Grid, Typography, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import ReactTypingEffect from 'react-typing-effect';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '3rem',
    minHeight: '1.8em',
    color: theme.palette.text.primary,
    [theme.breakpoints.only('md')]: {
      fontSize: '2rem',
      textAlign: 'center',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.8rem',
      textAlign: 'center',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.5rem',
      textAlign: 'center',
    },
  },
  withSpacing: {
    marginRight: '0.25em',
    [theme.breakpoints.only('md')]: {
      marginRight: '0.2em',
    },
    [theme.breakpoints.only('sm')]: {
      marginRight: '0.2em',
    },
    [theme.breakpoints.only('xs')]: {
      marginRight: '0.2em',
    },
  },
}));

const TypingLanguage = () => {
  const styles = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const typingSpeed = 75;

  return (
    <React.Fragment>
      <Typography className={styles.text}>I build responsive apps</Typography>
      <Grid
        container
        direction="row"
        justify={mdDown ? 'center' : undefined}
        alignItems="center"
      >
        <Grid item>
          <Typography className={[styles.text, styles.withSpacing].join(' ')}>
            with
          </Typography>
        </Grid>
        <Grid item>
          <ReactTypingEffect
            text={['Java/Kotlin.', 'Swift.', 'Angular.', 'React.', 'Node Js.']}
            speed={typingSpeed}
            eraseSpeed={typingSpeed}
            typingDelay={1000}
            eraseDelay={1500}
            cursorRenderer={(cursor) => (
              <span className={styles.text}>{cursor}</span>
            )}
            displayTextRenderer={(text) => {
              return (
                <Grid container direciton="row">
                  {text.split('').map((char, i) => {
                    return (
                      <Typography key={i} className={styles.text}>
                        {char}
                      </Typography>
                    );
                  })}
                </Grid>
              );
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TypingLanguage;
