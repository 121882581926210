import React from 'react';
import { Link, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { CodeBlock, androidstudio } from 'react-code-blocks';

export const blogStyles = makeStyles((theme) => ({
  divider: {
    marginTop: '2.5em',
    marginBottom: '2.5em',
    [theme.breakpoints.only('md')]: {
      marginTop: '2em',
      marginBottom: '2em',
    },
    [theme.breakpoints.only('sm')]: {
      marginTop: '1.5em',
      marginBottom: '1.5em',
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: '1em',
      marginBottom: '1em',
    },
  },
  contentText: {
    fontWeight: 300,
  },
  codeBlock: {
    marginTop: '2em',
    marginBottom: '2em',
  },
  screenshot: {
    height: '40em',
    width: 'auto',
    marginRight: '2em',
    [theme.breakpoints.down('sm')]: {
      height: '28em',
      marginRight: 0,
    },
    border: '1px solid #212121',
  },
}));

const preventDefault = (event) => {
  event.preventDefault();
  window.open(event.target.href, '_blank');
};

export const ClickableLink = ({ href, label }) => {
  return (
    <Link href={href} component="a" onClick={preventDefault}>
      {label}
    </Link>
  );
};
ClickableLink.defaultProps = {
  label: 'Link',
};

ClickableLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export const CodeSnippet = ({ codes }) => {
  const styles = blogStyles();
  return (
    <div className={styles.codeBlock}>
      <CodeBlock
        text={codes}
        language="java"
        showLineNumbers
        wrapLines
        theme={androidstudio}
      />
    </div>
  );
};
CodeSnippet.propTypes = {
  codes: PropTypes.string.isRequired,
};

export const ScreenShot = ({ source }) => {
  const styles = blogStyles();
  return <img src={source} alt="Output" className={styles.screenshot} />;
};
ScreenShot.propTypes = {
  source: PropTypes.string.isRequired,
};

export function getBlogDetails(path, blogs) {
  const blog = blogs.filter((blog) => blog.path === path);
  return blog[0];
}
