import { ConsoleLogObserver, AuthService } from 'ionic-appauth';
import {
  CapacitorBrowser,
  CapacitorSecureStorage,
} from 'ionic-appauth/lib/capacitor';

import { endpoints } from 'common/constants';
import { AxiosRequestor } from './axios.requestor';

export class Auth {
  static buildAuthInstance() {
    const authService = new AuthService(
      new CapacitorBrowser(),
      new CapacitorSecureStorage(),
      new AxiosRequestor()
    );

    authService.authConfig = {
      client_id: process.env.REACT_APP_CONNECT_KEYCLOAK_CLIENT_ID,
      server_host: endpoints.KEYCLOAK_URI,
      redirect_url: `${window.location.origin}/login-redirect`,
      end_session_redirect_url: `${window.location.origin}/end-redirect`,
      scopes: ['openid', 'offline_access'],
      pkce: true,
    };

    authService.addActionObserver(new ConsoleLogObserver());
    return authService;
  }

  static get instance() {
    if (!this.authService) {
      this.authService = this.buildAuthInstance();
    }

    return this.authService;
  }
}
