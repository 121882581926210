import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    width: '100%',
    height: '100%',
  },
  container: {
    padding: '2em',
    [theme.breakpoints.down('md')]: {
      padding: '1.5em',
    },
  },
  logo: {
    width: 'auto',
    height: ' 8em',
    [theme.breakpoints.only('md')]: {
      height: ' 6em',
    },
    [theme.breakpoints.only('sm')]: {
      height: ' 4em',
    },
    [theme.breakpoints.only('xs')]: {
      height: ' 3em',
    },
  },
  title: {
    marginTop: '0.5em',
  },
  description: {
    marginTop: '0.5em',
  },
}));

const SkillCard = ({ logo, title, content }) => {
  const styles = useStyles();
  return (
    <Paper className={styles.paper}>
      <Grid container direction="column" className={styles.container}>
        <Grid item>
          <img src={logo} alt="Skill Logo" className={styles.logo} />
        </Grid>
        <Typography variant="h4" className={styles.title}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={styles.description}>
          {content}
        </Typography>
      </Grid>
    </Paper>
  );
};

SkillCard.propTypes = {
  logo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default SkillCard;
