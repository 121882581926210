import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import AppFooter from 'components/AppFooter';
import ProgrammingLanguageBlock from './components/ProgrammingLanguageBlock';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: '4em',
    paddingRight: '4em',
    [theme.breakpoints.only('md')]: {
      paddingLeft: '4em',
      paddingRight: '4em',
    },
    [theme.breakpoints.only('sm')]: {
      paddingTop: '2em',
      paddingLeft: '6em',
      paddingRight: '6em',
    },
    [theme.breakpoints.only('xs')]: {
      paddingTop: '1.5em',
      paddingLeft: '2em',
      paddingRight: '2em',
    },
  },
}));

const Home = () => {
  const styles = useStyles();
  return (
    <React.Fragment>
      <Grid container className={styles.root}>
        <ProgrammingLanguageBlock />
      </Grid>
      <AppFooter />
    </React.Fragment>
  );
};

export default Home;
