import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Link, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  projectLink: {
    color: 'white',
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
});

const preventDefault = (event) => {
  event.preventDefault();
  window.open(event.target.href, '_blank');
};

const CareerTimelineProject = ({ name, link }) => {
  const styles = useStyles();
  return (
    <Grid>
      <Link
        href={link}
        component="a"
        onClick={preventDefault}
        className={styles.projectLink}
      >
        • {name}
      </Link>
    </Grid>
  );
};

CareerTimelineProject.defaultProps = {
  link: null,
};

CareerTimelineProject.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
};

export default CareerTimelineProject;
