import React from 'react';
import { useHistory } from 'react-router';
import { isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  button: {
    paddingLeft: 0,
    marginTop: '1em',
    marginBottom: '1em',
  },
  buttonText: {
    color: 'white',
    textTransform: 'none',
    minWidth: '6em',
    textAlign: 'left',
  },
}));

const AppHeaderMenuButton = ({ label, path, extraHandler }) => {
  const history = useHistory();
  const styles = useStyles();
  const onClickHandler = () => {
    if (!isUndefined(path)) {
      history.push(path);
    }
    extraHandler();
  };
  return (
    <Button
      disableRipple
      disableFocusRipple
      onClick={onClickHandler}
      className={styles.button}
    >
      <Typography className={styles.buttonText}>{label}</Typography>
    </Button>
  );
};

AppHeaderMenuButton.defaultProps = {
  path: undefined,
  extraHandler: () => {},
};

AppHeaderMenuButton.propTypes = {
  label: PropTypes.string.isRequired,
  path: PropTypes.string,
  extraHandler: PropTypes.func,
};

export default AppHeaderMenuButton;
