import React from 'react';
import Lottie from 'react-lottie';
import { Typography, Grid, makeStyles, useMediaQuery } from '@material-ui/core';

import notFoundAnimation from 'assets/animations/NotFoundAnimation/data';
import { useTheme } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '70vh',
  },
  divider: {
    backgroundColor: theme.palette.text.primary,
    height: '3.5em',
    width: '0.25em',
    marginRight: '2em',
    marginLeft: '2em',
    [theme.breakpoints.only('md')]: {
      height: '3.2em',
      width: '0.21em',
      marginRight: '1.7em',
      marginLeft: '1.7em',
    },
    [theme.breakpoints.only('sm')]: {
      height: '3em',
      width: '0.18em',
      marginRight: '1.45em',
      marginLeft: '1.45em',
    },
    [theme.breakpoints.only('xs')]: {
      height: '2.8em',
      width: '0.15em',
      marginRight: '1.2em',
      marginLeft: '1.2em',
    },
  },
  header: {
    color: theme.palette.text.primary,
    fontWeight: 700,
    fontSize: '2rem',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.75rem',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '1.35rem',
    },
  },
  message: {
    color: theme.palette.text.primary,
    fontSize: '1.2rem',
    [theme.breakpoints.only('md')]: {
      fontSize: '1.05rem',
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '0.95rem',
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '0.8rem',
    },
  },
}));

const PageNotFound = () => {
  const styles = useStyles();
  const theme = useTheme();
  const mdOnly = useMediaQuery(theme.breakpoints.only('md'));
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  let lottieDimen = 275;
  if (mdOnly) {
    lottieDimen = 220;
  }
  if (smDown) {
    lottieDimen = 175;
  }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: notFoundAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        justify="center"
        className={styles.root}
        spacing={smDown ? 0 : 2}
      >
        <Grid item>
          <Lottie
            options={defaultOptions}
            style={{
              height: lottieDimen,
              width: lottieDimen,
            }}
          />
        </Grid>
        <Grid item>
          <Grid container direction="row" justify="center" alignItems="center">
            <Grid item>
              <Typography className={styles.header}>404</Typography>
            </Grid>
            <Grid item>
              <div className={styles.divider} />
            </Grid>
            <Grid item>
              <Typography className={styles.message}>
                This page could not be found.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default PageNotFound;
