import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';

import {
  blogStyles,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output1 from 'assets/png/blogs/T4-output1.png';
import output2 from 'assets/png/blogs/T4-output2.png';
import {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
} from './codebase';

const BlogsAndroidFlashLightApp = () => {
  const styles = blogStyles();
  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />

      <Typography paragraph>
        We&apos;ve seen a lot of flashlight application in the Google Playstore
        and it&apos;s fairly a lot helpful to Android users. In this tutorial, I
        am going to teach you how to create a simple flashlight application and
        how to design your button&apos;s UI using an image view. Let&apos;s
        start by creating a new blank project in Android Studio and by adding
        these lines in your <b>AndroidManifest.xml</b>.
      </Typography>

      <CodeSnippet codes={step1} />

      <Typography paragraph>
        This uses-permission tag tells the Android OS that our app will be
        needing to access it&apos;s camera and the uses-feature tag tells what
        feature will be used by our application. Make sure to include these
        lines so that our application will run smoothly. We also need to remove
        the default action bar so that our application will look like this. We
        can do that by editing styles under <b>res/values</b> folder. Open
        styles.xml and change your current style to this.
      </Typography>

      <CodeSnippet codes={step2} />

      <Typography paragraph>
        We also need to make our application limited to portrait view. We can do
        that by adding this line in the activity tag in our{' '}
        <b>AndroidManifest.xml</b>
      </Typography>

      <CodeSnippet codes={step3} />

      <Typography paragraph>
        Now that we&apos;ve added all the necessary changes in our application,
        we can start creating our flashlight application. Open{' '}
        <b>activity_main.xml</b> and add the code provided below.
      </Typography>

      <CodeSnippet codes={step4} />

      <Typography paragraph>
        You can download the image resource here or you can provide your own.
        Make sure to add the image resource under drawable folder. Add these as
        global variables in your <b>MainActivity.java</b>
      </Typography>

      <CodeSnippet codes={step5} />

      <Typography paragraph>
        Now open <b>MainActivity.java</b> and add these methods after your
        onCreate method.
      </Typography>

      <CodeSnippet codes={step6} />

      <Typography paragraph>
        This will open and get the parameters of the camera.
      </Typography>

      <CodeSnippet codes={step7} />

      <Typography paragraph>
        These will control the camera&apos;s flash.
      </Typography>

      <CodeSnippet codes={step8} />

      <Typography paragraph>
        These will handle the camera when the activity&apos;s life cycle
        changes. For example, when we closes the application the camera flash
        will automatically be closed. This is very important because we
        don&apos;t want our camera&apos;s flash to remain turned on as it will
        drain our device&apos;s battery or leave a permanent damage to our
        device. Now copy and paste these codes inside your onCreate method.
      </Typography>

      <CodeSnippet codes={step9} />

      <Typography paragraph>
        Now you can run and test you app if it is working. The final output of
        this tutorial should be like this.
      </Typography>

      <ScreenShot source={output1} />
      <ScreenShot source={output2} />
      <Divider className={styles.divider} />
    </BlogContentLayout>
  );
};

export default BlogsAndroidFlashLightApp;
