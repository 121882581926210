import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setCurrentPage } from 'store/resourcesSlice';

const RouteListener = ({ children }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    return history.listen((location) => {
      window.scrollTo(0, 0);
      dispatch(setCurrentPage(location.pathname));
    });
  }, [history, dispatch]);
  return children;
};

export default RouteListener;
