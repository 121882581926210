import React from 'react';
import PropTypes from 'prop-types';
import { Grid, makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import ReactPlayer from 'react-player';

const useStyles = makeStyles(() => ({
  video: {
    marginBottom: '4em',
  },
}));

const VideoPlayer = ({ url }) => {
  const styles = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs'));
  const sm = useMediaQuery(theme.breakpoints.only('sm'));

  let width = '640px';
  let height = '360px';

  if (sm) {
    width = '480px';
    height = '270px';
  }

  if (xs) {
    width = '320px';
    height = '180px';
  }

  return (
    <Grid container justify="center">
      <Grid item className={styles.video}>
        <ReactPlayer url={url} width={width} height={height} />
      </Grid>
    </Grid>
  );
};

VideoPlayer.propTypes = {
  url: PropTypes.string.isRequired,
};

export default VideoPlayer;
