import React from 'react';
import PropTypes from 'prop-types';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineOppositeContent from '@material-ui/lab/TimelineOppositeContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import Typography from '@material-ui/core/Typography';
import { Grid, Hidden, makeStyles } from '@material-ui/core';

import codeTag from 'assets/svg/code-tag.svg';
import school from 'assets/svg/school.svg';

import CareerTimelineContent from './CareerTimelineContent';
import CareerTimelineProject from './CareerTimelineProject';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '2.5em',
    height: '2.5em',
    margin: '0.5em',
    fill: 'white',
  },
  timeline: {
    marginTop: '2em',
    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
}));

const TimelineLogo = ({ logo }) => {
  const styles = useStyles();
  return (
    <TimelineDot>
      <Hidden smDown>
        <img src={logo} alt="" className={styles.logo} />
      </Hidden>
    </TimelineDot>
  );
};

TimelineLogo.propTypes = {
  logo: PropTypes.string.isRequired,
};

const TimelineDate = ({ date }) => {
  const styles = useStyles();
  return (
    <Typography
      variant="body2"
      color="textSecondary"
      className={styles.timeline}
    >
      {date}
    </Typography>
  );
};

TimelineDate.propTypes = {
  date: PropTypes.string.isRequired,
};

const CareerTimeline = () => {
  return (
    <Timeline align="alternate">
      <TimelineItem>
        <TimelineOppositeContent>
          <TimelineDate date="June 2021 to Present" />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineLogo logo={codeTag} />
          <TimelineConnector />
        </TimelineSeparator>
        <CareerTimelineContent
          companyName="ProSource BPO Inc. - SiteMinder Ltd."
          jobDescription="Software Mobile Engineer"
          projects={[
            <CareerTimelineProject
              name="Little Hotelier"
              link="https://play.google.com/store/apps/details?id=com.siteminder.littlehotelier"
            />,
          ]}
        />
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <TimelineDate date="February 2021 to December 2021" />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineLogo logo={codeTag} />
          <TimelineConnector />
        </TimelineSeparator>
        <CareerTimelineContent
          companyName="Boomering Inc. - Australian Institute For Motor Sport Safety"
          jobDescription="Part-time Android Developer"
          projects={[
            <CareerTimelineProject
              name="Crashtag"
              link="https://play.google.com/store/apps/details?id=com.aimss.crashtag"
            />,
          ]}
        />
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <TimelineDate date="April 2020 to June 2021" />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineLogo logo={codeTag} />
          <TimelineConnector />
        </TimelineSeparator>
        <CareerTimelineContent
          companyName="mClinica Inc."
          jobDescription="Senior Android Developer"
          projects={[
            <CareerTimelineProject
              name="MYCare"
              link="https://play.google.com/store/apps/details?id=com.mclinica.mycare"
            />,
            <CareerTimelineProject
              name="MedKaki"
              link="https://play.google.com/store/apps/details?id=com.mclinica.medkaki"
            />,
            <CareerTimelineProject
              name="Stellar Program Indonesia"
              link="https://play.google.com/store/apps/details?id=com.mclinica.nvid"
            />,
            <CareerTimelineProject
              name="Stay On"
              link="https://play.google.com/store/apps/details?id=com.mclinica.stayon"
            />,
            <CareerTimelineProject name="In-Control" link="" />,
          ]}
        />
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <TimelineDate date="July 2019 to April 2020" />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineLogo logo={codeTag} />
          <TimelineConnector />
        </TimelineSeparator>
        <CareerTimelineContent
          companyName=" Oneflare Pty Ltd."
          jobDescription="Mobile Application Developer"
          projects={[
            <CareerTimelineProject
              name="Oneflare"
              link="https://play.google.com/store/apps/details?id=au.com.oneflare.androidapp&hl=en&gl=US"
            />,
            <CareerTimelineProject
              name="Oneflare Business"
              link="https://play.google.com/store/apps/details?id=au.com.oneflare.businessapp&hl=en&gl=US"
            />,
          ]}
        />
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <TimelineDate date="October 2016 to June 2019" />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineLogo logo={codeTag} />
          <TimelineConnector />
        </TimelineSeparator>
        <CareerTimelineContent
          companyName="Beestripe LLC"
          jobDescription="Android Developer"
          projects={[
            <CareerTimelineProject name="Better Cleaner" link="" />,
            <CareerTimelineProject name="Better Cleaner Lite" link="" />,
            <CareerTimelineProject name="Ocean Sweep" link="" />,
            <CareerTimelineProject name="Dog Daycare - Pet Grooming" link="" />,
          ]}
        />
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <TimelineDate date="December 2014 to December 2018" />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineLogo logo={school} />
          <TimelineConnector />
        </TimelineSeparator>
        <CareerTimelineContent
          companyName="Experts Academy"
          jobDescription="Part-time Android Developer and Trainer"
        />
      </TimelineItem>
      <TimelineItem>
        <TimelineOppositeContent>
          <TimelineDate date="May 2013 - October 2017" />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineLogo logo={school} />
          <TimelineConnector />
        </TimelineSeparator>
        <CareerTimelineContent
          companyName="Asia Pacific College"
          jobDescription="Engineering and IT Instructor"
        />
      </TimelineItem>
      <Grid container justify="center">
        <Grid item>
          <CareerTimelineContent
            companyName="August 2012 - Start of Career"
            jobDescription="I started developing Android apps using Eclipse IDE and Java while taking my bachelor's degree in Asia Pacific College. After several months of fun coding and experience, I was able to publish several apps/games to the Google Playstore."
            centered
          />
        </Grid>
      </Grid>
    </Timeline>
  );
};

export default CareerTimeline;
