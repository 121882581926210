import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';
import {
  blogStyles,
  ClickableLink,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output from 'assets/png/blogs/T1-output.png';
import { step1, step2, step3 } from './codebase';

const BlogsAndroidListView = () => {
  const styles = blogStyles();

  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />

      <Typography paragraph>
        Views are crucial components of your Android applications. They are
        essential components for user&apos;s interactions, and fragments. One of
        the most used views in many applications are ListViews. ListView is a
        view group that displays a list of scrollable items. The list items are
        automatically inserted to the list using an Adapter that pulls content
        from a source such as an array or database query and converts each item
        result into a view that&apos;s placed into the list.
      </Typography>

      <Typography paragraph>
        Listviews are used in various applications such as contact list,
        messenger apps, news feeds and etc.. Today, I am going to guide you on
        how to implement a simple ListView in Android. Before following this
        tutorial, the reader should already have a basic background on Android
        Development.
      </Typography>

      <Typography paragraph>
        We&apos;ll start this tutorial by creating a new Android project in
        Android Studio. If you don&apos;t have Android Studio installed yet, you
        can download it here:{' '}
        <ClickableLink
          href="https://developer.android.com/studio/index.html"
          label="Android Studio Download Link"
        />
        . After creating a new project, Let&apos;s first include a ListView in
        our main activity&apos;s XML Layout file. You can simply click and drag
        ListView from your Android Studio&apos;s palette onto the layout. Just
        make sure you are under the design tab.
      </Typography>

      <Typography paragraph>
        After that, click text tab and delete the layout&apos;s default padding
        in order to remove unwanted spaces beside the listview. Also, assign our
        ListView an ID so that we can use it in our <b>MainActivity.java</b>{' '}
        later. Your layout&apos;s code should be like this.
      </Typography>

      <CodeSnippet codes={step1} />

      <Typography paragraph>
        Now we&apos;re all set, we will now insert items to our ListView using
        an Adapter. Go to <b>MainActivity.java</b> and inside the onCreate
        method, create a string array having names of Android versions as its
        items. Your <b>MainActivity.java</b> should now have something like
        this.
      </Typography>

      <CodeSnippet codes={step2} />

      <Typography paragraph>
        Now we&apos;ll insert our string array to the ListView using an adapter
        First we need to create a new ArrayAdapter. Then we&apos;ll give a
        reference to our ListView so that we can bind the string array in our
        ArrayAdapter. Remember that our adapter is the one that pulls the
        content from our string array and converts them into a view that will be
        placed inside our ListView. Your <b>MainActivity.java</b> should now
        have something like this.
      </Typography>

      <CodeSnippet codes={step3} />

      <Typography paragraph>
        Now you can run and test you app if it is working. The final output of
        this tutorial should be like this.
      </Typography>

      <ScreenShot source={output} />
      <Divider className={styles.divider} />
    </BlogContentLayout>
  );
};

export default BlogsAndroidListView;
