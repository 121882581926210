import React, { useState } from 'react';
import {
  Divider,
  FormControl,
  Grid,
  Hidden,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Tab,
  Tabs,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/styles';

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: theme.palette.text.primary,
    fontSize: '1rem',
    fontWeight: 500,
    '&:focus': {
      opacity: 1,
    },
    [theme.breakpoints.down('md')]: {
      paddingLeft: '2em',
      paddingRight: '2em',
      minWidth: 'min-content',
    },
  },
}))((props) => <Tab disableRipple {...props} />);

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    width: '80%',
  },
}));

const BlogsTabs = () => {
  const styles = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFilterChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        alignItems={mdDown ? 'center' : undefined}
        spacing={2}
        className={styles.root}
      >
        <Hidden xsDown>
          <Grid item>
            <Tabs
              value={value}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{
                style: {
                  backgroundColor: theme.palette.text.primary,
                },
              }}
            >
              <StyledTab label="All" />
              <StyledTab label="Mobile" />
              <StyledTab label="Web" />
              <StyledTab label="Food" />
              <StyledTab label="Travel" />
            </Tabs>
            <Divider />
          </Grid>
        </Hidden>

        <Hidden smUp>
          <FormControl variant="outlined" className={styles.formControl}>
            <InputLabel>Blog Section</InputLabel>
            <Select
              value={value}
              label="Blog Section"
              onChange={handleFilterChange}
            >
              <MenuItem value={0}>All</MenuItem>
              <MenuItem value={1}>Mobile Development</MenuItem>
              <MenuItem value={2}>Web Development</MenuItem>
              <MenuItem value={3}>Food</MenuItem>
              <MenuItem value={4}>Travel</MenuItem>
            </Select>
          </FormControl>
        </Hidden>
      </Grid>
    </React.Fragment>
  );
};

export default BlogsTabs;
