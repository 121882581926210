import React from 'react';
import { useSelector } from 'react-redux';
import { selectBlogs } from 'selectors/blogs';
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

import BlogContentItem from './BlogContentItem';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '40em',
  },
  banner: {
    height: '12em',
    width: 'auto',
    margin: '1em',
  },
  blogContainer: {
    marginTop: '2em',
    marginBottom: '2em',
  },
}));

const BlogContentMain = () => {
  const styles = useStyles();
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const blogs = useSelector(selectBlogs);
  return (
    <React.Fragment>
      <Grid
        container
        direction="column"
        alignItems={mdDown ? 'center' : undefined}
        spacing={2}
        className={styles.root}
      >
        <Grid item>
          <Grid
            container
            className={styles.blogContainer}
            justify="space-evenly"
          >
            {blogs.map((blog) => {
              return (
                <BlogContentItem
                  key={blog.path}
                  thumbnail={blog.banner}
                  title={blog.title}
                  tags={blog.tags}
                  author={blog.author}
                  duration={blog.duration}
                  date={blog.date}
                  path={blog.path}
                />
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default BlogContentMain;
