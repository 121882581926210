import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';

import {
  blogStyles,
  ClickableLink,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output1 from 'assets/png/blogs/T8-output1.png';
import output2 from 'assets/png/blogs/T8-output2.png';
import output3 from 'assets/png/blogs/T8-output3.png';

import {
  step1,
  step2,
  step3,
  step4,
  step5,
  step6,
  step7,
  step8,
  step9,
  step10,
  step11,
} from './codebase';

const BlogsAndroidGoogleSignIn = () => {
  const styles = blogStyles();
  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />

      <Typography paragraph>
        The quickest and easiest way of integrating user registration in your
        application is through existing social networks such as Google,
        Facebook, VK and etc.. In this tutorial, I&apos;m going to teach you how
        to implement Google Plus Sign In using Android.
      </Typography>
      <Typography paragraph>
        Google Plus is a social network provided by Google. Google Plus Sign In
        lets your users sign in their existing Google account in your Android
        app. Once integrated, you&apos;ll be able to get their basic information
        such as name, email, display picture, gender and other details. Using
        Google Sign-In makes your registration process easier and more
        comfortable to users since they will no longer input their information
        manually. You also don&apos;t need to validate if the email used by the
        user is legit since it&apos;s already connected to Google.
      </Typography>
      <Typography paragraph component="span">
        Before we can use Google Sign-In in our application, we need to do the
        following steps.
        <ul>
          1.) Update your Android Support Repository, Google Play Services, and
          Google Repository in your SDK manager.
        </ul>
        <ul>
          2.) Create a new project in Google Developer Console. You can quickly
          add new project here:{' '}
          <ClickableLink href="https://developers.google.com/mobile/add" />
        </ul>
        <ul>3.) Click GET STARTED then choose Android App.</ul>
        <ul>
          4.) Provide the form with the name of your app and package name then
          select your country/region.
        </ul>
        <ul>5.) Select Google Sign In.</ul>
        <ul>6.) Provide your app&apos;s SHA-1 certificate</ul>
      </Typography>

      <Typography paragraph>
        The quickest way to get your SHA-1 certificate is to generate a signing
        report. You can find it in your gradle properties. That&apos;s it! We
        can now integrate Google Plus Sign-in in our Android app. Now open the
        project that you used earlier and create new packages and classes just
        like what you see below. Then add these dependencies in your
        build.gradle so that we can use Google sign in.
      </Typography>

      <CodeSnippet codes={step1} />
      <Typography paragraph>
        Next is to add this permission in your <b>AndroidManifest.xml</b>.
      </Typography>
      <CodeSnippet codes={step2} />
      <Typography paragraph>
        We&apos;ll get three information when a user logged in. Their Google
        unique id, name and email. First we create a model where we can store
        these information. Open <b>User.java</b> then add the following codes.
      </Typography>
      <CodeSnippet codes={step3} />
      <Typography paragraph>
        Next Open <b>Config.java</b> then add the following codes. I&apos;ve
        store all the shared preference keys here so we can use it in other
        classes.
      </Typography>
      <CodeSnippet codes={step4} />
      <Typography paragraph>
        When a user logged in, we need to save its current information in a
        shared preference so that our app can check if a user is logged in or
        not. Open <b>MyPreferenceManager.java</b> then add the following codes.
      </Typography>
      <CodeSnippet codes={step5} />
      <Typography paragraph>
        Now there are some variables that needs to be accessed in different
        activities. One solution for this is to make them globally available by
        creating a singleton class. A singleton in Java is a class for which
        only one instance can be created. It provides a global point of access
        this instance. Open <b>Appcontroller.java</b> and add the following
        codes.
      </Typography>
      <CodeSnippet codes={step6} />
      <Typography paragraph>
        Then open <b>AndroidManifest.xml</b> and add this in the application
        tag.
      </Typography>
      <CodeSnippet codes={step7} />
      <Typography paragraph>
        Now let&apos;s add the Google sign-in button in our login activity. Open{' '}
        <b>activity_login.xml</b> then add the following layout codes.
      </Typography>
      <CodeSnippet codes={step8} />
      <Typography paragraph>
        Then open your <b>Login.java</b> and add the following codes. I&apos;ve
        put some comments so you can understand the codes provided.
      </Typography>
      <CodeSnippet codes={step9} />
      <Typography paragraph>
        Then open <b>activity_home.xml</b> then add the following layout codes.
      </Typography>
      <CodeSnippet codes={step10} />
      <Typography paragraph>
        Lastly, open <b>Home.java</b> and add the following codes. We&apos;ll
        display the user&apos;s information here. We also have a sign out and
        revoke access button here.
      </Typography>
      <CodeSnippet codes={step11} />
      <Typography paragraph>
        Now you can run the app and test if it is working.
      </Typography>

      <ScreenShot source={output1} />
      <ScreenShot source={output2} />
      <ScreenShot source={output3} />
      <Divider className={styles.divider} />
    </BlogContentLayout>
  );
};

export default BlogsAndroidGoogleSignIn;
