import React from 'react';
import PropTypes from 'prop-types';
import { TimelineContent } from '@material-ui/lab';
import { makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    maxWidth: '40em',
    paddingLeft: '2em',
    paddingRight: '2em',
    paddingTop: '1em',
    paddingBottom: '1em',
    marginBottom: '4em',
    background: '#455A64',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '1em',
      paddingRight: '1em',
      paddingTop: '0.5em',
      paddingBottom: '0.5em',
    },
  },
  companyName: {
    color: 'white',
  },
  jobDescription: {
    color: 'white',
    marginTop: '0.5em',
    marginBottom: '0.5em',
  },
}));

const CareerTimelineContent = ({
  companyName,
  jobDescription,
  projects,
  centered,
}) => {
  const styles = useStyles();
  const projectsItem = [];

  projects.forEach((project) => {
    projectsItem.push(project);
  });

  return (
    <TimelineContent>
      <Paper elevation={1} className={styles.paper}>
        <Typography
          variant="h6"
          className={styles.companyName}
          style={{ textAlign: centered ? 'center' : undefined }}
        >
          {companyName}
        </Typography>
        <Typography
          variant="subtitle1"
          className={styles.jobDescription}
          style={{ textAlign: centered ? 'center' : undefined }}
        >
          {jobDescription}
        </Typography>
        {projectsItem.length > 0 ? (
          <Typography
            variant="subtitle1"
            className={styles.jobDescription}
            style={{ textAlign: centered ? 'center' : undefined }}
          >
            <b>Projects:</b>
          </Typography>
        ) : null}

        {projectsItem}
      </Paper>
    </TimelineContent>
  );
};

CareerTimelineContent.defaultProps = {
  centered: false,
  projects: [],
};

CareerTimelineContent.propTypes = {
  companyName: PropTypes.string.isRequired,
  jobDescription: PropTypes.string.isRequired,
  projects: PropTypes.array,
  centered: PropTypes.bool,
};

export default CareerTimelineContent;
