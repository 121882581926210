export const step1 = `<?xml version="1.0" encoding="utf-8"?>
<LinearLayout    
    xmlns:android="http://schemas.android.com/apk/res/android"    
    xmlns:tools="http://schemas.android.com/tools"    
    android:id="@+id/activity_main"    
    android:layout_width="match_parent"    
    android:layout_height="match_parent"    
    android:gravity="center"    
    android:orientation="vertical"    
    android:padding="16dp"    
    tools:context="info.androidprime.dialogbuilder.MainActivity">

        <Button        
            android:id="@+id/btnShowAlertDialog"        
            android:layout_width="match_parent"        
            android:layout_height="wrap_content"        
            android:text="Show Alert Dialog"/>

</LinearLayout>
`;

export const step2 = `public class MainActivity extends AppCompatActivity {    
    @Override    
    protected void onCreate(Bundle savedInstanceState) {        
        super.onCreate(savedInstanceState);        
        setContentView(R.layout.activity_main);        
        /let's move alert dialog codes here to make our code neat        
        initializeAlertDialog();    
    }    
    
    private void initializeAlertDialog() {        
        //let's build our alert dialog here        
        final AlertDialog.Builder alertDialogBuilder = new AlertDialog.Builder(MainActivity.this);        
        lertDialogBuilder.setCancelable(false);        
        alertDialogBuilder.setTitle("Sample Alert Dialog");        
        alertDialogBuilder.setMessage("Congratulations! You've just created an alert dialog.");        
        alertDialogBuilder.setNegativeButton("Cancel", new DialogInterface.OnClickListener() {            
            @Override            
            public void onClick(DialogInterface dialogInterface, int i) {                
                dialogInterface.cancel();                
                //add something here. Will trigger when user pressed Cancel button            
            }        
        });        
        alertDialogBuilder.setPositiveButton("Okay", new DialogInterface.OnClickListener() {            
            @Override            
            public void onClick(DialogInterface dialogInterface, int i) {                
                dialogInterface.cancel();                
                //add something here. Will trigger when user pressed Okay button                
                //make sure to remove dialogInterface.cancel();            
            }        
        });        
        final AlertDialog alertDialog = alertDialogBuilder.create();        
        //now let's give a reference to our button and show the alert dialog        
        //when user pressed the button        
        Button showAlertDialog = (Button) findViewById(R.id.btnShowAlertDialog);        
        showAlertDialog.setOnClickListener(new OnClickListener() {            
            @Override            
            public void onClick(View view) {                
                alertDialog.show(); 
                // This will show the alert dialog we built            
            }       
         });    
    }
}`;

export const step3 = `<?xml version="1.0" encoding="utf-8"?>
<LinearLayout xmlns:android="http://schemas.android.com/apk/res/android"              
    android:id="@+id/prompt_password"              
    android:layout_width="match_parent"              
    android:layout_height="match_parent"              
    android:gravity="center|top"              
    android:orientation="vertical"> 

        <ImageView        
        android:layout_width="match_parent"        
        android:layout_height="wrap_content"        
        android:adjustViewBounds="true"        
        android:scaleType="fitXY"        
        android:src="@drawable/banner"/>    

        <TextView        
        android:layout_width="wrap_content"        
        android:layout_height="wrap_content"        
        android:layout_marginTop="16dp"        
        android:text="This is very nice! Looks good."        
        android:textStyle="bold"/>    

        <TextView        
        android:layout_width="wrap_content"        
        android:layout_height="wrap_content"        
        android:layout_marginBottom="25dp"        
        android:layout_marginTop="10dp"        
        android:text="visit us at www.androidprime.info"/>

</LinearLayout>
`;

export const step4 = `<?xml version="1.0" encoding="utf-8"?>
<LinearLayout    
    xmlns:android="http://schemas.android.com/apk/res/android"    
    xmlns:tools="http://schemas.android.com/tools"    
    android:id="@+id/activity_main"    
    android:layout_width="match_parent"    
    android:layout_height="match_parent"    
    android:gravity="center"    
    android:orientation="vertical"    
    android:padding="16dp"    
    tools:context="info.androidprime.dialogbuilder.MainActivity">    

        <Button        
        android:id="@+id/btnShowAlertDialog"        
        android:layout_width="match_parent"        
        android:layout_height="wrap_content"        
        android:text="Show Alert Dialog"/>   

        <Button        
        android:id="@+id/btnShowCustomDialog"        
        android:layout_width="match_parent"        
        android:layout_height="wrap_content"        
        android:text="Show Custom Dialog"/>

</LinearLayout>`;

export const step5 = `public class MainActivity extends AppCompatActivity {    
    
    @Override    
    protected void onCreate(Bundle savedInstanceState) {        
        super.onCreate(savedInstanceState);        
        setContentView(R.layout.activity_main);        
        //let's move alert dialog codes here to make our code neat        
        initializeAlertDialog();        
        //let's move custom dialog codes here to make our code neat        
        initializeCustomDialog();    
    }    
    
    private void initializeAlertDialog() {        
        //let's build our alert dialog here        
        final AlertDialog.Builder alertDialogBuilder = new AlertDialog.Builder(MainActivity.this);        
        alertDialogBuilder.setCancelable(false);        
        alertDialogBuilder.setTitle("Sample Alert Dialog");        
        alertDialogBuilder.setMessage("Congratulations! You've just created an alert dialog.");        
        alertDialogBuilder.setNegativeButton("Cancel", new DialogInterface.OnClickListener() {            
            @Override            
            public void onClick(DialogInterface dialogInterface, int i) {                
                dialogInterface.cancel();                
                //add something here. Will trigger when user pressed Cancel button            
            }        
        });        
        alertDialogBuilder.setPositiveButton("Okay", new DialogInterface.OnClickListener() {            
            @Override            
            public void onClick(DialogInterface dialogInterface, int i) {                
                dialogInterface.cancel();                
                //add something here. Will trigger when user pressed Okay button                
                //make sure to remove dialogInterface.cancel();            
            }        
        });        
        final AlertDialog alertDialog = alertDialogBuilder.create();        
        //now let's give a reference to our button and show the alert dialog        
        //when user pressed the button        
        Button showAlertDialog = (Button) findViewById(R.id.btnShowAlertDialog);        
        showAlertDialog.setOnClickListener(new OnClickListener() {            
            @Override            
            public void onClick(View view) {                
                alertDialog.show(); 
                // This will show the alert dialog we built            
            }        
        });    
    }    
    
    private void initializeCustomDialog() {        
        // let's inflate our layout inside the dialog using        
        // LayoutInflater class        
        LayoutInflater li = LayoutInflater.from(MainActivity.this);        
        View promptsView = li.inflate(R.layout.prompt_about, null);        
        AlertDialog.Builder alertDialogBuilder = new AlertDialog.Builder(MainActivity.this);        
        alertDialogBuilder.setView(promptsView);        
        alertDialogBuilder.setCancelable(true);        
        final AlertDialog customDialog = alertDialogBuilder.create();        
        Button showCustomDialog = (Button) findViewById(R.id.btnShowCustomDialog);        
        showCustomDialog.setOnClickListener(new OnClickListener() {            
            @Override            
            public void onClick(View view) {                
                customDialog.show(); 
                // This will show the alert dialog we built            
            }        
        });    
    }
}
`;
