import React from 'react';
import { Button, Grid, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  link: {
    width: '100%',
    color: 'white',
    opacity: '0.7',
    '&:hover': {
      opacity: '1',
    },
  },
  button: {
    textTransform: 'none',
    padding: 0,
    marginTop: '0.5em',
    marginBottom: '0.5em',
    marginLeft: '1em',
    marginRight: '8em',
  },
  divider: {
    height: '10em',
    width: '0.05em',
    background: 'white',
    opacity: 0.3,
  },
}));

const MenuLinks = () => {
  const styles = useStyles();
  const history = useHistory();

  const onClickLinkHandler = (path) => {
    history.push(path);
  };
  return (
    <React.Fragment>
      <Grid item>
        <Grid container>
          <Grid item>
            <div className={styles.divider} />
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Button
                className={styles.button}
                disableRipple
                onClick={() => onClickLinkHandler('/home')}
              >
                <Typography variant="subtitle1" className={styles.link}>
                  Home
                </Typography>
              </Button>
              <Button
                className={styles.button}
                disableRipple
                onClick={() => onClickLinkHandler('/profile')}
              >
                <Typography variant="subtitle1" className={styles.link}>
                  Profile
                </Typography>
              </Button>
              <Button
                className={styles.button}
                disableRipple
                onClick={() => onClickLinkHandler('/games')}
              >
                <Typography variant="subtitle1" className={styles.link}>
                  Games
                </Typography>
              </Button>
              <Button
                className={styles.button}
                disableRipple
                onClick={() => onClickLinkHandler('/blogs')}
              >
                <Typography variant="subtitle1" className={styles.link}>
                  Blogs
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default MenuLinks;
