import React from 'react';
import { Divider, Typography } from '@material-ui/core';
import BlogContentLayout from 'pages/Blogs/components/BlogContentLayout';
import {
  blogStyles,
  CodeSnippet,
  ScreenShot,
} from 'pages/Blogs/components/helper';

import output1 from 'assets/png/blogs/T3-output1.png';
import output2 from 'assets/png/blogs/T3-output2.png';
import { step1, step2, step3, step4, step5 } from './codebase';

const BlogsAndroidDialog = () => {
  const styles = blogStyles();
  return (
    <BlogContentLayout>
      <Divider className={styles.divider} />

      <Typography paragraph>
        This tutorial provides a brief explanation on the usage of some useful
        Android UI components that most real-life applications use.
        Specifically, this tutorial will cover how to use dialogs and even how
        to create your own custom dialog. Dialogs are small windows that appear
        in front of an activity. The activities behind them are either blurred
        or dimmed. There are different types of dialog. The most commonly used
        are Alert Dialog, Progress Dialog, DatePicker Dialog and TimePicker
        Dialog.
      </Typography>

      <Typography paragraph>
        <b>Alert Dialog</b> - A dialog that can manage up to three buttons, or a
        list of selectable items that can include checkboxes or radio buttons.
      </Typography>

      <Typography paragraph>
        <b>Progress Dialog</b> - A dialog that displays either a progress wheel
        or a progress bar. Commonly used when your app is doing an action that
        takes a certain period of time to finish. e.g. downloading files,
        account sign in, and etc..
      </Typography>

      <Typography paragraph>
        <b>Date Picker Dialog</b> - A dialog that allows a user to select a
        certain date.
      </Typography>

      <Typography paragraph>
        <b>Time Picker Dialog</b> - A dialog that allows a user to select a
        certain time.
      </Typography>

      <Typography paragraph>
        In this tutorial, We are going to focus on building alert dialog and
        later on we&apos;ll try to create our customized alert dialog.
        Let&apos;s start by creating a new blank project in Android Studio.
        Creating an alert dialog is very fast and easy to do. One advantage of
        using dialog is that you do not need to include it in your{' '}
        <b>AndroidManifest.xml</b>. Which means using dialogs is like build and
        display.
      </Typography>

      <Typography paragraph>
        Open your <b>activity_main.xml</b> and add one button. The alert dialog
        will be displayed when you clicked this button.
      </Typography>

      <CodeSnippet codes={step1} />

      <Typography paragraph>
        You should have something like this in your layout. Now open{' '}
        <b>MainActivity.java</b> then copy and paste the codes provided below.
        I&apos;ve put some comments there so you can understand what we are
        doing.
      </Typography>

      <CodeSnippet codes={step2} />

      <Typography paragraph>
        In summary, If we want to create an alert dialog we usually follow these
        steps.
      </Typography>

      <Typography paragraph component="span">
        <ul>
          1. Create a new alert dialog builder using the AlertDialog.Builder.
        </ul>
        <ul>
          2. Set the title, message, and icon of your alert dialog. You can
          include/exclude any of the following. It&apos;s really up to you what
          you need in your alert dialog.{' '}
        </ul>
        <ul>
          3. Set the buttons for your alert dialog. You can set positive,
          negative and neutral button.{' '}
        </ul>
        <ul>4. Build and show your alert dialog.</ul>
      </Typography>

      <Typography paragraph>
        Very easy to implement right? Now you can run and test you app if it is
        working. The final output of this tutorial should be like this. Now what
        if we want to change the content of the alert dialog by putting our own
        custom layout inside it. It will be easier to use alert dialog rather
        than creating your own class in handling such display. For example I
        have a real-life application where I want user to view a certain
        information. I want my alert dialog to look something like this.
      </Typography>

      <Typography paragraph>
        Let&apos;s start by creating a new XML layout file for this and name it{' '}
        <b>prompt_about.xml</b>. Copy and paste the provided code in your{' '}
        <b>prompt_about.xml</b>. You can download the image here.
      </Typography>

      <CodeSnippet codes={step3} />

      <Typography paragraph>
        Open your <b>activity_main.xml</b> and add another button which will
        display our custom dialog when clicked by the user.
      </Typography>

      <CodeSnippet codes={step4} />

      <Typography paragraph>
        Now that we have our customized layout, we can now start our coding.
        Open <b>MainActivity.java</b> then copy and paste the provided code.
      </Typography>

      <CodeSnippet codes={step5} />

      <Typography paragraph>
        Now you can run and test you app if it is working. The final output of
        this tutorial should be like this.
      </Typography>

      <ScreenShot source={output1} />
      <ScreenShot source={output2} />
      <Divider className={styles.divider} />
    </BlogContentLayout>
  );
};

export default BlogsAndroidDialog;
